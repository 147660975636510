import axios from 'axios';
import Edit from "../Sensor/Edit";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function View(props) {
    const [openEditModal, setOpenEditModal] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState(null)
    const [total, setTotal] = useState(0)
    let [limit, setLimit] = useState(10)
    const [data, setData] = useState({})
    const [search, setSearch] = useState({
        type: "BB_LABEL",
        search: ""
    })
    let columns = [
        {
            field: 'sensor_id',
            headerName: 'Sensor Id',
            flex: 1,
        },
        {
            field: 'bb_label',
            headerName: 'Board Label',
            flex: 1,
        },
        {
            field: 'sensor_housing_id',
            headerName: 'Housing Id',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'current_build_type',
            headerName: 'Current Build Type',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'coupling_serial_1',
        },
        {
            field: 'coupling_serial_2',
        },
        {
            field: 'tested_pressure',
            headerName: 'Tested Pressure',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        // {
        //     field: 'tested_by',
        //     headerName: 'Tester',
        //     flex: 1,
        //     valueGetter: ({ value }) =>
        //         `${value ? value : '-'}`,
        // },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'operational',
            headerName: 'Operational',
            flex: 1,
            valueGetter: ({ value }) => {
                if (value || value === false) {
                    return value
                } else {
                    return '-'
                }
            },
        },
        {
            field: 'water_test_date',
            headerName: 'Water Test Date',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }).split(',')[0] : '-'}`,
        },
        {
            field: 'calibration_test_date',
            headerName: 'Calibration Test Date',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }).split(',')[0] : '-'}`,
        },
        {
            field: 'time_inserted',
            headerName: 'Inserted Date',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }).slice(0, 10) : '-'}`,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Button id={params.row.id} variant="outlined" color="warning" size="small" onClick={() => {
                        setData(params.row)
                        setOpenEditModal(true)
                    }} startIcon={<EditIcon />} >Edit</Button>
                )
            },
        }
    ]
    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>Displaying {results.length} of {total ? total : 0}</b></Box>
        );
    }
    async function getSensorSearch(token) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_SENSOR_BOARDS_SEARCH_API_URL.replace(":type",
            search.type).replace(":search", search.search)}`, { headers })
            .then((response) => {
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    if (value.most_recent) {
                        mapped_Value.leak_test = value.most_recent.leak_test
                        mapped_Value.coupling_serial_1 = value.most_recent.coupling_serial_1
                        mapped_Value.coupling_serial_2 = value.most_recent.coupling_serial_2
                        mapped_Value.sensor_housing_id = value.most_recent.sensor_housing_id
                        mapped_Value.current_build_type = value.current_build_type
                        mapped_Value.tested_by = value.most_recent.tested_by
                        mapped_Value.tested_pressure = value.most_recent.tested_pressure
                        mapped_Value.water_test_date = value.most_recent.water_test_date
                        mapped_Value.notes = value.most_recent.notes
                        mapped_Value.operational = value.most_recent.operational
                    }
                    mapped_Value.sensor_id = value.sensor_id
                    mapped_Value.bb_label = value.bb_label
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.calibration_test_date = value.calibration_test_date
                    mapped_Value.id = `${value._id}`
                    return mapped_Value
                })
                setResults(data)
                setLoadMore(false)
                setTotal(data.length)
            })
    }

    const getSensorBoards = useCallback(async (isSearchCancel) => {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        let limit_to_put = !isSearchCancel ? limit : 10
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_SENSOR_BOARDS_GET_API_URL.replace(":limit", limit_to_put)}`, { headers })
            .then((response) => {
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    if (value.most_recent) {
                        mapped_Value.leak_test = value.most_recent.leak_test
                        mapped_Value.coupling_serial_1 = value.most_recent.coupling_serial_1
                        mapped_Value.coupling_serial_2 = value.most_recent.coupling_serial_2
                        mapped_Value.sensor_housing_id = value.most_recent.sensor_housing_id
                        mapped_Value.current_build_type = value.current_build_type
                        mapped_Value.tested_by = value.most_recent.tested_by
                        mapped_Value.tested_pressure = value.most_recent.tested_pressure
                        mapped_Value.water_test_date = value.most_recent.water_test_date
                        mapped_Value.notes = value.most_recent.notes
                        mapped_Value.operational = value.most_recent.operational
                    }
                    mapped_Value.sensor_id = value.sensor_id
                    mapped_Value.bb_label = value.bb_label
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.calibration_test_date = value.calibration_test_date
                    mapped_Value.id = `${value._id}`
                    return mapped_Value
                })
                if (isSearchCancel) {
                    setResults(data)
                } else {
                    if (results && results.length) {
                        setResults(values => values.concat(data))
                    } else {
                        setResults(data)
                    }
                    setLimit(limit + 10)
                }
                setLoadMore(response.data.hasMore)
                setTotal(response.data.total_items)
            })

    }, [limit, props.token, results])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'Sensor_Boards'
                }} />
            </GridToolbarContainer>
        );
    }

    function handleDialogClose() {
        setOpenEditModal(!openEditModal)
    }

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        search[name] = value
        setSearch(prev => ({
            ...prev,
            [name]: value
        }))
    };

    useEffect(() => {
        if (props.token && !results) {
            setLoading(true)
            getSensorBoards()
        }
    }, [getSensorBoards, results, props.token])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Box component="span" sx={{ p: 2, borderColor: '#EBF5FB', border: '1px dashed', m: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="sensor-search-type-label">Type</InputLabel>
                                <Select
                                    required
                                    id="sensor-search-type-label-field"
                                    name="type"
                                    labelId="sensor-search-type-label"
                                    value={search.type}
                                    label="Type"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"SENSOR_ID"} key={1}>{'Sensor Id'}</MenuItem>
                                    <MenuItem value={"BB_LABEL"} key={2}>{'Board Label'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="sensor-search-field" name='search' label="Search" variant="outlined"
                                value={search.search}
                                onChange={handleChange}
                                sx={{
                                    width: "50%"
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        getSensorSearch(props.token)
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <Button variant="outlined" color="primary" startIcon={<SearchIcon />}
                                onClick={() => {
                                    getSensorSearch(props.token)
                                }}>Search</Button>
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <Button variant="outlined" color="warning"
                                onClick={() => {
                                    setSearch({
                                        search: '',
                                        type: 'BB_LABEL'
                                    })
                                    getSensorBoards(true)
                                }} startIcon={<ClearIcon />}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    {
                        results ?
                            <DataGrid
                                rows={results}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent
                                }}
                                columnVisibilityModel={{
                                    // Hide these columns
                                    coupling_serial_1: false,
                                    coupling_serial_2: false,
                                }}
                                loading={loading}
                                rowCount={total}
                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>
            </Grid>}
            <Box m={1} p={1}>
                {loadMore && <Button variant="outlined" onClick={() => {
                    getSensorBoards(false)
                }}>Load More</Button>}
            </Box>
            {!loading &&
                <Dialog open={openEditModal} onClose={handleDialogClose} maxWidth="lg">
                    <DialogContent>
                        <Edit handleDialogClose={handleDialogClose} data={data}
                            title={'Edit Sensor'} setLoading={setLoading} token={props.token} getSensorBoards={getSensorBoards} />
                    </DialogContent>
                </Dialog>

            }
        </React.Fragment>
    )
}

import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import React, { useState } from "react";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function AddEquipment(props) {
    const [data, setData] = useState(props.data)
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        setData(prev => ({
            ...prev,
            [name]: value
        }))
    };
    const handleSubmit = (event) => {
        event.preventDefault()
        insertEquipment()
    }
    async function getEquipmentId(type) {
        setLoading(true)
        let model = ""
        if (type === 'BATTERYPACK') {
            model = "BP"
        } else if (type === 'TCMPACK') {
            model = "TP"
        } else if (type === 'SOLAR30W') {
            model = "S30W"
        } else if (type === 'SOLAR50W') {
            model = "S50W"
        } else if (type === 'GATEWAY') {
            model = "G"
        }
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_EQUIPMENT_COUNT_API_URL.replace(":type", model).replace(":year", new Date().getFullYear())}`,
            { headers })
            .then((response) => {
                let data = response.data ? response.data.data : []
                setLoading(false)
                setData(prev => ({
                    ...prev,
                    'model': model,
                    'equipment_id': data[0].id,
                    'type': type
                }))
            })
    }
    async function insertEquipment() {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        setLoading(true)
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_EQUIPMENT_INSERT_API_URL}`, data, { headers })
            .then(() => {
                setLoading(false)
                props.handleDialogClose()
                props.getEquipments(props.token, true)
            })
    }

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Grid container rowSpacing={5} columnSpacing={3} mt={2} alignItems="center" justify="center">
                    <Grid item xs={4}>
                        <FormControl sx={{
                            width: "75%"
                        }}>
                            <InputLabel id="type-version-label">Equipment Type</InputLabel>
                            <Select
                                required
                                id="form-factor-select"
                                labelId="type-version-label"
                                value={data.type}
                                label="Type of Equipment"
                                disabled={props.isEdit}
                                onChange={(e) => {
                                    let { value } = e.target
                                    getEquipmentId(value)
                                }}
                            >
                                <MenuItem value={''} key={0}>{''}</MenuItem>
                                <MenuItem value={'BATTERYPACK'} key={1}>{'Battery Pack'}</MenuItem>
                                <MenuItem value={'TCMPACK'} key={2}>{'TCM Pack'}</MenuItem>
                                <MenuItem value={'SOLAR30W'} key={3}>{'Solar 30W'}</MenuItem>
                                <MenuItem value={'SOLAR50W'} key={4}>{'Solar 50W'}</MenuItem>
                                <MenuItem value={'GATEWAY'} key={5}>{'Gateway'}</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField required id="equipment_id" name='equipment_id' label="Equipment Id" variant="outlined"
                            multiline
                            value={data.equipment_id}
                            onChange={handleChange}
                            sx={{
                                width: "75%"
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField required id="status" name='status' label="Status(Pass/Fail)" variant="outlined"
                            multiline
                            value={data.status}
                            onChange={(e) => {
                                let { value } = e.target
                                setData(prev => ({
                                    ...prev,
                                    'status': value ? value.toLocaleUpperCase() : ""
                                }))
                            }}
                            sx={{
                                width: "75%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                required
                                label="Date Water Tested"
                                value={data.water_test_date}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setData(prev => ({
                                            ...prev,
                                            'water_test_date': newValue.startOf('day'),
                                        }))
                                    } else {
                                        setData(prev => ({
                                            ...prev,
                                            'water_test_date': null,
                                        }))
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "75%"
                                    }} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                required
                                label="Date Electronic Tested"
                                value={data.electronic_test_date}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setData(prev => ({
                                            ...prev,
                                            'electronic_test_date': newValue.startOf('day'),
                                        }))
                                    } else {
                                        setData(prev => ({
                                            ...prev,
                                            'electronic_test_date': null,
                                        }))
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "75%"
                                    }} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{
                            width: "75%"
                        }}>
                            <InputLabel id="test-version-label">Test</InputLabel>
                            <Select
                                required
                                id="test-select"
                                labelId="type-version-label"
                                value={data.test}
                                name={'test'}
                                label="Type of Test"
                                onChange={handleChange}
                            >
                                <MenuItem value={'WATER'} key={1}>{'Water'}</MenuItem>
                                <MenuItem value={'ELECTRONIC'} key={2}>{'Electronic'}</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        props.isEdit &&
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "75%"
                            }}>
                                <InputLabel id="test-version-label">Last Test</InputLabel>
                                <Select
                                    required
                                    id="test-select"
                                    labelId="type-version-label"
                                    value={props.data.test}
                                    disabled={true}
                                    name={'test'}
                                    label="Type of Test"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'WATER'} key={1}>{'Water'}</MenuItem>
                                    <MenuItem value={'ELECTRONIC'} key={2}>{'Electronic'}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={4}>
                        <FormControl sx={{
                            width: "75%"
                        }}>
                            <InputLabel id="tester-version-label">Tester</InputLabel>
                            <Select
                                required
                                id="tester-select"
                                labelId="type-version-label"
                                value={data.tester}
                                name={'tester'}
                                label="Type of tester"
                                onChange={handleChange}
                            >
                                {CONSTANTS.FIELD_PERSONAL.map((name, id) => (
                                    <MenuItem
                                        key={id}
                                        value={name.toLocaleUpperCase()}>
                                        {name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        props.isEdit &&
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "75%"
                            }}>
                                <InputLabel id="operational-dropdown-label-equipment">Operational</InputLabel>
                                <Select
                                    required
                                    id="operational-select-equipment"
                                    name="operational"
                                    labelId="operational-dropdown-label-equipment"
                                    value={data.operational}
                                    label="Operational"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={true} key={1}>{'True'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'False'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={4}>
                        <Button variant="contained" onClick={handleSubmit}
                            disabled={props.data === data}>Submit</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" color="warning" onClick={props.handleDialogClose}>Cancel</Button>
                    </Grid>
                </Grid>}
        </React.Fragment >
    )
}
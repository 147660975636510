
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import React, { useState } from "react";
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export default function EditSensor(props) {
    let [data, setData] = useState(props.data)

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        data[name] = value
        setData(prev => ({
            ...prev,
            [name]: value
        }))
    };
    const handleSubmit = (event) => {
        event.preventDefault()
        let payload = data
        payload.time_inserted = new Date()
        payload.caliberation_test_date = new Date(payload.calibration_test_date)
        payload.water_test_date = new Date(payload.water_test_date)
        delete payload.calibration_test_date
        props.handleDialogClose()
        props.setLoading(true)
        updateSensor(payload)
        

    }
    async function updateSensor(data) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_SENSOR_BOARDS_UPDATE_API_URL}`, data, { headers })
            .then(() => {
                props.getSensorBoards(true)
            })
    }

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            <Grid container rowSpacing={5} columnSpacing={2} mt={2}>
                <Grid item xs={4}>
                    <TextField id="sensor_id" name='sensor_id' label="Sensor Id" variant="outlined"
                        value={data.sensor_id}
                        // onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="bb_label" name='bb_label' label="Board Label" variant="outlined"
                        value={data.bb_label}
                        // onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="sensor_housing_id" name='sensor_housing_id' label="Housing Id" variant="outlined"
                        value={data.sensor_housing_id}
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="coupling_serial_1" name='coupling_serial_1' label="Coupling-1" variant="outlined"
                        value={data.coupling_serial_1}
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="coupling_serial_2" name='coupling_serial_2' label="Coupling-2" variant="outlined"
                        value={data.coupling_serial_2}
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="tested_pressure" name='tested_pressure' label="Tested Pressure" variant="outlined"
                        value={data.tested_pressure}
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="notes" name='notes' label="Notes" variant="outlined"
                        value={data.notes}
                        multiline
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="tested_by" name='tested_by' label="Tester" variant="outlined"
                        value={data.tested_by}
                        onChange={handleChange}
                        sx={{
                            width: "50%"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{
                        width: "50%"
                    }}>
                        <InputLabel id="leaktest_dropdown_label">Leak Test</InputLabel>
                        <Select
                            required
                            id="leaktest-dropdown"
                            name="leak_test"
                            labelId="leaktest_dropdown_label"
                            value={data.leak_test}
                            label="Leak Test"
                            onChange={handleChange}
                        >
                            <MenuItem value={"PASS"} key={1}>{'Pass'}</MenuItem>
                            <MenuItem value={"FAIL"} key={2}>{'Fail'}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Water Test Date"
                            value={data.water_test_date ? data.water_test_date : ''}
                            onChange={(newValue) => {
                                setData(prev => ({
                                    ...prev,
                                    water_test_date: new Date(newValue.startOf('day')),
                                }))
                            }}
                            renderInput={(params) => <TextField {...params}
                                sx={{
                                    width: "50%"
                                }} />}
                        />
                    </LocalizationProvider>

                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            required
                            label="Caliberation Test Date"
                            value={data.caliberation_test_date}
                            onChange={(newValue) => {
                                setData(prev => ({
                                    ...prev,
                                    caliberation_test_date: new Date(newValue.startOf('day')),
                                }))
                            }}
                            renderInput={(params) => <TextField {...params}
                                sx={{
                                    width: "50%"
                                }} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{
                        width: "50%"
                    }}>
                        <InputLabel id="operational-dropdown-label-quantifier">Operational</InputLabel>
                        <Select
                            required
                            id="operational-select-quantifier"
                            name="operational"
                            labelId="operational-dropdown-label-quantifier"
                            value={data.operational}
                            label="Operational"
                            onChange={handleChange}
                        >
                            <MenuItem value={true} key={1}>{'True'}</MenuItem>
                            <MenuItem value={false} key={2}>{'False'}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                    <Button sx={{ width: "100%" }} variant="outlined" color="warning" onClick={props.handleDialogClose}>Cancel</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button sx={{ width: "100%" }} variant="outlined" color="primary" onClick={handleSubmit}>Submit</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ChecklistAdd from './../Checklist/Add';
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DialogContent from '@mui/material/DialogContent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function ViewChecklist(props) {
    const [openDetailModal, setOpenDetailModal] = useState(false)
    const [loadMoreSearch, setLoadMoreSearch] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState(null)
    const [search, setSearch] = useState({
        sites: "",
        trip_start: "",
        trip_end: ""
    })
    const [limit, setLimit] = useState(10)
    const [editData, setEditData] = useState({})
    const [searchLimit, setSearchLimit] = useState(10)
    const [siteData, setSiteData] = useState([])
    const [total, setTotal] = useState(0)

    let columns = [
        {
            field: 'sites',
            headerName: 'Site(s)',
            valueGetter: ({ value }) =>
                `${value ? value.join(", ") : '-'}`,
            flex: 1
        },
        {
            field: 'trip_start',
            headerName: 'Date (Field Trip)',
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toISOString().split('T')[0] : '-'}`,
            flex: 1
        },
        {
            field: 'quantifier_installing',
            headerName: 'Installed Quantififer',
            flex: 1
        },
        {
            field: 'borehole_drilling',
            headerName: 'Borehole Work',
            flex: 1
        },
        {
            field: 'extra_quantifier',
            headerName: 'Quantifier Consumables',
            flex: 1
        },
        {
            field: 'installing_distributor',
            headerName: 'Installing Distributor',
            flex: 1
        },
        {
            field: 'distributor_consumables_taking',
            headerName: 'Distributor Consumables',
            flex: 1
        },
        {
            field: 'working_tracer',
            headerName: 'Tracer Work',
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            disableClickEventBubbling: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <VisibilityIcon id={params.row.id} color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
                        setEditData(params.row)
                        setOpenDetailModal(true)

                    }} />
                )
            },
        },
    ]


    async function getChecklist(token, fromStart) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = fromStart ? 10 : limit
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.CHECKLIST_GET_API.replace(":limit", limit_to_put)}`, { headers })
            .then((response) => {
                let temp = limit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value) => {
                    value.id = value._id
                    return value
                })
                if (fromStart) {
                    setResults(data)
                    setLimit(temp += 10)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setLimit(temp += 10)
                }
                setLoadMore(response.data.hasMore)
                setTotal(response.data.total_items)
            })
    }

    async function getChecklistSearch(token) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = searchLimit
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.CHECKLIST_SEARCH_API.replace(":limit", limit_to_put)}`,
            search, { headers })
            .then((response) => {
                let temp = searchLimit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value) => {
                    value.id = value._id
                    return value
                })
                if (searchLimit === 10) {
                    setResults(data)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setSearchLimit(temp += 10)
                }
                setLoadMoreSearch(response.data.hasMore)
                setTotal(response.data.total_items)
            })
    }


    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        setSearch(prev => ({
            ...prev,
            [name]: value
        }))
    };

    async function getSites(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                data.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0
                })
                setSiteData(data)
            })
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'CheckLists'
                }} />
            </GridToolbarContainer>
        );
    }

    function handleDetailDialogClose() {
        setOpenDetailModal(!openDetailModal)
    }

    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>Displaying {results.length} of {total ? total : 0}</b></Box>
        );
    }
    useEffect(() => {
        if (props.token && !results) {
            setLoading(true)
            getChecklist(props.token, true)
            getSites(props.token)
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [props.token, results])

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Box component="span" sx={{ p: 2, borderColor: '#EBF5FB', border: '1px dashed', m: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
                        <Grid item xs={3}>
                            <TextField id="checklist-search-site" name='sites' label="Site(s)" variant="outlined"
                                multiline
                                value={search.sites}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Range - From"
                                    value={search.trip_start}
                                    onChange={(newValue) => {
                                        setSearch(prev => ({
                                            ...prev,
                                            trip_start: newValue ? new Date(newValue.startOf('day')) : '',
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2.5}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Range - To"
                                    value={search.trip_end}
                                    onChange={(newValue) => {
                                        setSearch(prev => ({
                                            ...prev,
                                            trip_end: newValue ? new Date(newValue.endOf('day')) : '',
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <Button variant="outlined" color="primary" startIcon={<SearchIcon />}
                                onClick={() => {
                                    getChecklistSearch(props.token)
                                }}>Search</Button>
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <Button variant="outlined" color="warning"
                                onClick={() => {
                                    setSearch({
                                        sites: '',
                                        trip_start: '',
                                        trip_end: '',
                                    })
                                    setSearchLimit(10)
                                    setLoadMoreSearch(false)
                                    getChecklist(props.token, true)
                                }} startIcon={<ClearIcon />}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    {
                        results ?
                            <DataGrid
                                rows={results}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent
                                }}
                                loading={loading}
                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>
            </Grid>}
            <Box m={1} p={1}>
                {loadMore && <Button variant="outlined" onClick={() => {
                    getChecklist(props.token, false)
                }}>Load More</Button>}
            </Box>
            <Box m={1} p={1}>
                {loadMoreSearch && <Button variant="outlined" onClick={() => {
                    getChecklistSearch(props.token)
                }}>Load More</Button>}
            </Box>
            {!loading &&
                <Dialog open={openDetailModal} onClose={handleDetailDialogClose} maxWidth="xl">
                    <DialogContent>
                        <ChecklistAdd token={props.token} data={editData} title={`View Checklist`} isDetail={true} siteData={siteData} handleDetailDialogClose={handleDetailDialogClose} getChecklist={getChecklist} />
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>
    )
}
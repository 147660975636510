import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CONSTANTS from '../../helpers/constants';
import EditIcon from '@mui/icons-material/Edit';
import AddQuantifier from './../Quantifier/Add';
import TextField from '@mui/material/TextField';
import LocalStorage from "../Login/local-storage";
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState, useCallback } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


export default function ViewQuantifier(props) {
    let ls = new LocalStorage()
    const [openEditModal, setOpenEditModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState(null)
    const [limit, setLimit] = useState(10)
    const [data, setData] = useState({})
    const [sensor, setSensor] = useState([])
    const [sensorData, setSensorData] = useState([])
    const [siteData, setSiteData] = useState([])
    const [search, setSearch] = useState({
        type: "SITE",
        search: ""
    })
    const [total, setTotal] = useState(0)
    let columns = [
        {
            field: 'site',
            headerName: 'Site',
            // flex: 1,
        },
        {
            field: 'quantifier_id',
            headerName: 'Quantifier Id',
            // flex: 1,
        },
        {
            field: 'quantifier_name',
            headerName: 'Name',
            // flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'tcm',
            headerName: 'TCM',
            // flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'sensors',
            headerName: 'Sensors',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0.1}>
                        {params.row.sensors.sort((a, b) => {
                            if (a.board < b.board) {
                                return -1
                            } else {
                                return 1
                            }
                        }).map(item =>
                            <Chip key={item.sensor_id} label={item.bb_label ? item.bb_label : item.sensor_id} />
                        )}
                    </Stack>
                );
            },
        },
        {
            field: 'operational',
            headerName: 'Operational',
            // flex: 1,
            valueGetter: ({ value }) => {
                if (value || value === false) {
                    return value
                } else {
                    return '-'
                }
            },
        },
        {
            field: 'electronic_test_date',
            headerName: 'Electronic Test',
            flex: 0.5,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }).split(',')[0] : '-'}`,
        },
        {
            field: 'field_install_date',
            headerName: 'Field Install',
            flex: 0.5,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }).split(',')[0] : '-'}`,
        },
        {
            field: 'power_infrastructure',
        },
        {
            field: 'build_type',
        },
        {
            field: 'action',
            headerName: 'Action',
            // flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <EditIcon 
                            id={`${params.row.id}-edit`}
                            style={{ cursor: "pointer" }}
                            color='warning' 
                            onClick={() => {
                                setData(params.row)
                                setSensor(params.row.sensors)
                                setOpenEditModal(true)
                            }}/>
                        <DeleteForeverIcon 
                            id={`${params.row.id}-delete`}
                            style={{ cursor: "pointer" }}
                            color='error' 
                            onClick={() => {
                                setData(params.row)
                                setSensor(params.row.sensors)
                                setConfirmModal(true)
                            }}/>
                    </Stack>
                    )
            },
        }
    ]

    const getQuantifiers = useCallback(async (token, fromStart) => {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = !fromStart ? limit : 10
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_QUANTIFIERS_GET_API_URL.replace(":limit", limit_to_put)}`, { headers })
            .then((response) => {
                let temp = limit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    mapped_Value.site = value.site
                    mapped_Value.quantifier_id = value.quantifier_id
                    mapped_Value.quantifier_name = value.quantifier_name
                    mapped_Value.tcm = value.tcm
                    mapped_Value.notes = value.notes
                    mapped_Value.sensors = value.sensors
                    mapped_Value.electronic_test_date = value.electronic_test_date
                    mapped_Value.field_install_date = value.field_install_date
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.zoho_bundling_date = value.zoho_bundling_date
                    mapped_Value.operational = value.operational
                    mapped_Value.id = value._id
                    mapped_Value.build_type = value.build_type
                    mapped_Value.power_infrastructure = value.power_infrastructure
                    return mapped_Value
                })
                if (fromStart) {
                    setResults(data)
                    setLimit(temp += 10)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setLimit(temp += 10)
                }
                setTotal(response.data.total_items)
                setLoadMore(response.data.hasMore)
            })
    }, [limit, results])

    async function getQuantifiersSearch(token) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_QUANTIFIERS_SEARCH_API_URL.replace(":type",
            search.type).replace(":search", search.search)}`, { headers })
            .then((response) => {
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    mapped_Value.site = value.site
                    mapped_Value.quantifier_id = value.quantifier_id
                    mapped_Value.quantifier_name = value.quantifier_name
                    mapped_Value.tcm = value.tcm
                    mapped_Value.notes = value.notes
                    mapped_Value.sensors = value.sensors
                    mapped_Value.electronic_test_date = value.electronic_test_date
                    mapped_Value.field_install_date = value.field_install_date
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.operational = value.operational
                    mapped_Value.id = value._id
                    mapped_Value.build_type = value.build_type
                    mapped_Value.power_infrastructure = value.power_infrastructure
                    return mapped_Value
                })
                setResults(data)
                setLoadMore(false)
                setTotal(data.length)
            })
    }

    async function getSites(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                setSiteData(data)
            })
    }

    async function getSensors(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_ALL_SENSORS_GET_API_URL}`, { headers })
            .then((response) => {
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    if (value.most_recent) {
                        mapped_Value.leak_test = value.most_recent.leak_test
                        mapped_Value.coupling_serial_1 = value.most_recent.coupling_serial_1
                        mapped_Value.coupling_serial_2 = value.most_recent.coupling_serial_2
                        mapped_Value.sensor_housing_id = value.most_recent.sensor_housing_id
                        mapped_Value.tested_by = value.most_recent.tested_by
                        mapped_Value.tested_pressure = value.most_recent.tested_pressure
                        mapped_Value.water_test_date = value.most_recent.water_test_date
                        mapped_Value.notes = value.most_recent.notes
                    }
                    mapped_Value.sensor_id = value.sensor_id
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.caliberation_test_date = value.caliberation_test_date
                    mapped_Value.id = `${value.sensor_id}${idx}${value.time_inserted}${value.caliberation_test_date}${value.most_recent}`
                    delete mapped_Value.versions
                    delete mapped_Value.most_recent
                    return mapped_Value
                })
                setSensorData(data)
            })
    }

    async function postUpdatedQuantifiers(data) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_QUANTIFIER_UPDATE_API_URL}`, data, { headers })
            .then(() => {
                getSensors(props.token)
                setLoading(false)
            })
    }

    async function postDeleteQuantifiers(data) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_QUANTIFIER_DELETE_API_URL}`, data, { headers })
            .then(() => {
                getQuantifiers(props.token)
                setLoading(false)
            })
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'Quantifiers'
                }} />
            </GridToolbarContainer>
        );
    }

    function handleDialogClose() {
        setOpenEditModal(!openEditModal)
    }

    function handleDialogCloseConfirm() {
        setConfirmModal(!confirmModal)
    }

    function submitEdit() {
        let data_to_send = data
        data_to_send.sensors = sensor
        data_to_send.site_search = data_to_send.site.toUpperCase()
        setLoading(true)
        postUpdatedQuantifiers(data_to_send)
        handleDialogClose()
    }
    
    function deleteQuantifier() {
        setLoading(true)
        let zoho_token = ls.getLocalStorageItemZoho().token
        let data_to_send = data
        data_to_send.sensors = sensor
        data_to_send.site_search = data_to_send.site.toUpperCase()
        data_to_send.zohoToken = zoho_token
        postDeleteQuantifiers(data_to_send)
        handleDialogCloseConfirm()
    }

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        search[name] = value
        setSearch(prev => ({
            ...prev,
            [name]: value
        }))
    };

    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>Displaying {results.length} of {total ? total : 0}</b></Box>
        );
    }

    useEffect(() => {
        if (props.token && !results) {
            setLoading(true)
            getSites(props.token)
            getSensors(props.token)
            getQuantifiers(props.token)
        }
    }, [getQuantifiers, props.token, results])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Box component="span" sx={{ p: 2, borderColor: '#EBF5FB', border: '1px dashed', m: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
                        <Grid item xs={5}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="quantifier-search-type-label">Type</InputLabel>
                                <Select
                                    required
                                    id="quantifier-search-type-label-field"
                                    name="type"
                                    labelId="quantifier-search-type-label"
                                    value={search.type}
                                    label="Type"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"SITE"} key={1}>{'Site'}</MenuItem>
                                    <MenuItem value={"QUANTIFIER_ID"} key={2}>{'Quantifier Id'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="quantifier-search-field" name='search' label="Search" variant="outlined"
                                value={search.search}
                                onChange={handleChange}
                                sx={{
                                    width: "50%"
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        getQuantifiersSearch(props.token)
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <Button variant="outlined" color="primary" startIcon={<SearchIcon />}
                                onClick={() => {
                                    getQuantifiersSearch(props.token)
                                }}>Search</Button>
                        </Grid>
                        <Grid item xs={1} mt={2}>
                            <Button variant="outlined" color="warning"
                                onClick={() => {
                                    setSearch({
                                        search: '',
                                        type: 'SITE'
                                    })
                                    getQuantifiers(props.token, true)
                                }} startIcon={<ClearIcon />}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    {
                        results ?
                            <DataGrid
                                rows={results}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent
                                }}
                                columnVisibilityModel={{
                                    power_infrastructure: false,
                                    build_type: false,
                                }}
                                loading={loading}
                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>
            </Grid>}
            <Box m={1} p={1}>
                {loadMore && <Button variant="outlined" onClick={() => {
                    getQuantifiers(props.token, false)
                }}>Load More</Button>}
            </Box>
            {!loading &&
                <Dialog open={openEditModal} onClose={handleDialogClose} maxWidth="lg">
                    <DialogContent>
                        <AddQuantifier title={'Edit Quantifier'} token={props.token} isEdit={true} data={data}
                            setData={setData} sensors={sensor} setSensors={setSensor} sensorData={sensorData} siteData={siteData} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="warning" onClick={handleDialogClose}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={submitEdit}>Submit</Button>
                    </DialogActions>
                </Dialog>

            }
            {!loading &&
                <Dialog 
                    open={confirmModal} 
                    onClose={handleDialogCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        Permenantly Delete {data.quantifier_id}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this quantifer and its respective bundles on ZOHO ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="warning" onClick={handleDialogCloseConfirm}>Cancel</Button>
                        <Button variant="outlined" color="primary" onClick={deleteQuantifier}>Delete</Button>
                    </DialogActions>
                </Dialog>

            }
        </React.Fragment>
    )
}
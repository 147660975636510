import axios from 'axios';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState, useCallback } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function Packet(props) {
    const [site, setSite] = useState(["All"]);
    const [startDate, setStartDate] = useState(moment().subtract(91, 'days').startOf('day'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'days').startOf('day'));
    const [loading, setLoading] = useState(false);
    const [siteData, setSiteDate] = useState(null);
    const [results, setResults] = useState(null);
    const [rows, setRows] = useState([]);
    let columns = [
        {
            field: 'site',
            headerName: 'Site',
            flex: 1,
        },
        {
            field: 'overall_sensor_expected',
            headerName: 'Sensor Data (Expected)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'actual_sensor',
            headerName: 'Sensor Data (Received)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'overall_expected',
            headerName: 'Overall Data (Expected)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'overall_actual',
            headerName: 'Overall Data (Received)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'packet_recieve',
            headerName: 'Packet Received (Rate)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value.toFixed(2) : '-'}`,
        },
        {
            field: 'overall_rate',
            headerName: 'Overall Data (Rate)',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value.toFixed(2) : '-'}`,
        },
        {
            field: "start_date",
            headerName: "Start Date",
        },
        {
            field: "end_date",
            headerName: "End Date",
        },
        {
            field: "days",
            headerName: "Days",
        }
    ]
    const handleChange = (event) => {
        const { target: { value } } = event;
        if (site[0] === 'All' && value.length > 1) {
            setSite(value.filter(item => item !== 'All'))
        } else if (value.includes('All')) {
            setSite(['All'])
        } else {
            setSite(typeof value === 'string' ? value.split(',') : value);
        }
    };

    const handleSubmit = () => {
        let sites_to_send = site
        if (site.length === 1 && site[0] === 'All') {
            sites_to_send = siteData.filter(obj => obj.Name !== "All")
        } else {
            let site_filter = []
            sites_to_send = siteData.filter(obj => {
                let name = obj.Name
                site_filter = site.filter((value) => value === name)
                if (site_filter.length) {
                    return true
                }
                return false
            })
            sites_to_send = sites_to_send.filter(obj => obj.Name !== "All")
        }

        getSiteStatus(sites_to_send)
    }
    async function getSiteStatus(sites_to_send) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_STATUS_API_URL.replace(':starttimestamp',
            startDate.toDate().getTime()).replace(':endtimestamp', endDate.toDate().getTime())}`, sites_to_send, { headers })
            .then((response) => {
                setLoading(false)
                let data = response.data
                let length = response.data.length
                if (length > 1) {
                    let overall_average = {
                        "site": "Average",
                        "expected_sensor": calculate_average(data, "expected_sensor", length).toFixed(2),
                        "overall_sensor_expected": calculate_average(data, "overall_sensor_expected", length).toFixed(2),
                        "actual_sensor": calculate_average(data, "actual_sensor", length).toFixed(2),
                        "overall_tcm_expected": calculate_average(data, "overall_tcm_expected", length).toFixed(2),
                        "packet_recieve": calculate_average(data, "packet_recieve", length),
                        "overall_expected": calculate_average(data, "overall_expected", length).toFixed(2),
                        "overall_actual": calculate_average(data, "overall_actual", length).toFixed(2),
                        "overall_rate": calculate_average(data, "overall_rate", length),
                        "start_date": data[0].start_date,
                        "end_date": data[0].end_date,
                        "days": data[0].days
                    }
                    data.push(overall_average)
                }
                setRows(data.map((value, idx) => {
                    let mapped_Value = {}
                    mapped_Value = value
                    mapped_Value.id = idx
                    return mapped_Value
                }))
                setResults(data)
            })
    }
    function calculate_average(data, key, length) {
        let count_zeros = 0
        let result = data.reduce(function (sum, values) {
            if (values[key]) {
                return sum + parseFloat(values[key]);
            }
            count_zeros += 1
            return sum

        }, 0) / (length - count_zeros)
        return result
    }
    const getSites = useCallback(async (token) => {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                data.push({
                    Name: "All",
                    _id: 1234,
                    fullName: "All Sites"
                })
                setLoading(false)
                setSiteDate(data)
            })
    }, [])
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: `Packet_loss_${new Date(startDate).toDateString("YYYY-MM-DD")}_${new Date(endDate).toDateString("YYYY-MM-DD")}`

                }} />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        if (props.token) {
            setLoading(true)
            getSites(props.token)
        }
    }, [props.token, getSites])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={4}>
                    <InputLabel id="site-dropdown-label">Site</InputLabel>
                    <Select
                        multiple
                        name="site"
                        labelId="site-dropdown-label"
                        id="site-dropdown-select"
                        label="Site"
                        value={site}
                        onChange={handleChange}
                        input={<OutlinedInput label="Sites" />}
                    >
                        {siteData && siteData.map(data =>
                            <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                        )}
                    </Select>

                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="sdate-dropdown-label">Start Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Start Date"
                            labelId="sdate-dropdown-label"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(newValue.startOf('day'));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </Grid>

                <Grid item xs={3}>
                    <InputLabel id="edate-dropdown-label">End Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="End Date"
                            labelId="edate-dropdown-label"
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(newValue.startOf('day'));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </Grid>

                <Grid item xs={2}>
                    <Box m={2} pt={3}>
                        <Button variant="contained" onClick={handleSubmit} >Submit</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2}>
                    {
                        results ?
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                columnVisibilityModel={{
                                    // Hide these columns
                                    start_date: false,
                                    end_date: false,
                                    days: false
                                }}

                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>

            </Grid>}
        </React.Fragment>
    )
}
import axios from 'axios';
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LocalStorage from "./local-storage";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../assets/ems-logo-only-01.png';
import backgroundImage from "../../assets/Image-Logo.png";
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ems-inc.ca">
        Environmental Material Science
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide(props) {
  const [loginError, setLoginError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate();

  async function HandleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const parsed_data = {
      username: data.get('username'),
      password: data.get('password'),
    };
    if (parsed_data.username && parsed_data.password) {
      //Do Loader work
      if (!loading) {
        setLoading(true)
      }
      // Calling Login API
      try {
        let login_data = await axios.post(CONSTANTS.DEFAULT_API_URL + CONSTANTS.LOGIN_API_URL, parsed_data)
        if (login_data.status === 200) {
          let ls = new LocalStorage()
          ls.setLocalStorageItem(login_data.data.token)
          props.setToken(login_data.data.token)
          navigate(CONSTANTS.PAGE_DASHBOARD)
          setLoading(false)
        }
      }
      catch (exception) {
        if(exception.response) 
          setLoginError(exception.response.data.message)
        else
          setLoginError(exception)
        setLoading(false)
      }
    } else {
      setLoginError('Username/Password Missing')
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {!loading ? <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar src={logo} align="center" />
            <Typography component="h1" variant="h5">
              Venato Log in
            </Typography>
            <Box component="form" noValidate onSubmit={HandleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                sx={{ width: 1 }}
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <LockOutlinedIcon />
                <p style={{
                  "marginLeft": "1%"
                }}>Log In</p>
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              {loginError && <Stack sx={{ width: '100%' }}>
                <Alert severity="error">{loginError}</Alert>
              </Stack>}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box> :
            <Box sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <CircularProgress />
            </Box>
          }

        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

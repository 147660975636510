import axios from 'axios';
import SecureLS from "secure-ls";
import CONSTANTS from '../../helpers/constants';

class LocalStorage {
  key = 'USER'
  zoho = 'ZOHO'
  zoho_refresh = 'REFRESH_TOKEN_ZOHO'
  secureLS = new SecureLS({
    encodingType: "aes",
    isCompression: false,
    encryptionSecret: process.env.REACT_APP_KEY
  });

  getZohoToken = () => {
    let refresh_token = this.getLocalStorageItemZohoRefresh()
    let token = this.getLocalStorageItem()
    if (refresh_token && token) {
      let payload_data = {
        'refresh_token': refresh_token,
        'client_id': process.env.REACT_APP_ZOHO_CLIENT_ID,
        'client_secret': process.env.REACT_APP_ZOHO_CLIENT_SECRET,
        'redirect_uri': `${CONSTANTS.DEVELOPMENT_URL_APP}/dashboard`,
        'grant_type': 'refresh_token',
      }
      axios.post(`${CONSTANTS.ZOHO_PROXY_SERVER}`, payload_data,
        {
          headers: {
            'authorization': `Bearer ${token}`,
            'Target-URL': CONSTANTS.ZOHO_GET_TOKEN_API,
            'Type': 'FormData'
          }
        }).then((response) => {
          axios.get(`${CONSTANTS.ZOHO_PROXY_SERVER}`,
            {
              headers: {
                'authorization': `Bearer ${token}`,
                'Zoho-Auth': `Zoho-oauthtoken ${response.data.access_token}`,
                'Target-URL': CONSTANTS.ZOHO_CURRENT_USER_API
              }
            }).then((user_response) => {
              this.secureLS.set(this.zoho, {
                token: response.data.access_token,
                user_name: user_response.data.user.name,
                user_id: user_response.data.user.user_id,
                timestamp: new Date().getTime()
              });
            })
        })
    } else if (!refresh_token) {
      let ZOHO_CODE_API = CONSTANTS.ZOHO_REFRESH_TOKEN_API.replace(":client_id",
        process.env.REACT_APP_ZOHO_CLIENT_ID).replace(":scope", CONSTANTS.ZOHO_SCOPE)
      window.location.replace(ZOHO_CODE_API)
    } else {
      window.location.replace(CONSTANTS.DEVELOPMENT_URL_APP)
    }
  }
  setLocalStorageItem = (item, type) => {
    if (!type) {
      this.secureLS.set(this.key, {
        token: item,
        timestamp: new Date().getTime()
      });
    } else if (type === 'ZOHO') {
      this.secureLS.set(this.zoho, {
        token: item,
        timestamp: new Date().getTime()
      });
    } else {
      this.secureLS.set(this.zoho_refresh, {
        token: item,
      });
    }
  };
  setZohoTokenAndUser = (token, zohoToken) => {
    let mainLSItem = this.secureLS.get(this.zoho);
    axios.get(`${CONSTANTS.ZOHO_PROXY_SERVER}`,
      {
        headers: {
          'authorization': `Bearer ${token}`,
          'Zoho-Auth': `Zoho-oauthtoken ${zohoToken}`,
          'Target-URL': CONSTANTS.ZOHO_CURRENT_USER_API
        }
      }).then((response) => {
        this.secureLS.set(this.zoho, {
          token: zohoToken,
          user_name: response.data.user.name,
          user_id: response.data.user.user_id,
          timestamp: mainLSItem.timestamp
        });
      })
  }
  getLocalStorageItem = () => {
    let mainLSItem = this.secureLS.get(this.key);
    if (mainLSItem !== null && mainLSItem !== "") {
      let token_timestamp = mainLSItem.timestamp
      let current_timestamp = new Date().getTime()
      // Token Expires in 12 hours on server, I'm removing it in 11
      let allowed_timestamp = token_timestamp + (11 * 60 * 60 * 1000)
      if (current_timestamp < allowed_timestamp) {
        return mainLSItem.token;
      }
      else {
        this.removeLocalStorageItem(this.key)
      }
    }
    return false;
  };
  getLocalStorageItemZoho = () => {
    let mainLSItem = this.secureLS.get(this.zoho);
    if (mainLSItem !== null && mainLSItem !== "") {
      let token_timestamp = mainLSItem.timestamp
      let current_timestamp = new Date().getTime()
      // Token Expires in 1 hours on zoho
      let allowed_timestamp = token_timestamp + (1 * 60 * 60 * 1000)
      if (current_timestamp < allowed_timestamp) {
        return mainLSItem;
      }
      else {
        this.removeLocalStorageItem(this.zoho)
        this.getZohoToken()
        mainLSItem = this.secureLS.get(this.zoho);
        return mainLSItem
      }
    }
    return false;
  }

  getLocalStorageItemZohoRefresh = () => {
    let mainLSItem = this.secureLS.get(this.zoho_refresh);
    if (mainLSItem !== null && mainLSItem !== "") {
      return mainLSItem.token;
    }
    return false;
  }

  getZohoTokenObj = () => {
    let mainLSItem = this.secureLS.get(this.zoho);
    if (mainLSItem !== null && mainLSItem !== "") {
      let timestamps = mainLSItem.timestamp + (1 * 60 * 60 * 1000)
      return timestamps
    } else {
      return Date.now()
    }
  }

  removeLocalStorageItem = (key) => {
    this.secureLS.remove(key);
  };
}

export default LocalStorage;

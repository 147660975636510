import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';

export default function Built(props) {
    return (
        <React.Fragment>
            <Title>Number of Quantifiers - Can be Built</Title>
            <Typography component="p" variant="h4">
                {props.quantInventory ? props.quantInventory.can_built : '-'}
            </Typography>
            <Typography component="h3" variant="h6" color="primary" gutterBottom>
                In Hand (Zoho)
            </Typography>
            <Typography component="p" variant="h4">
                {props.quantInventory ? props.quantInventory.in_hand : '-'}
            </Typography>
        </React.Fragment>
    );
}
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../helpers/constants";
import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { List, Collapse, ListItemText, ListItemIcon, ListItemButton } from "@mui/material";
import {
   Add,
   Task,
   Notes,
   Sensors,
   Dataset,
   Handyman,
   BarChart,
   GridView,
   DriveEta,
   CheckBox,
   Category,
   FactCheck,
   OpenInNew,
   ExpandMore,
   ExpandLess,
   SafetyCheck,
   AddLocationAlt,
   SignalCellularOff,
} from "@mui/icons-material";

export default function MainListItems() {
   const navigate = useNavigate();
   const [openNotes, setOpenNotes] = useState(false);
   const [openField, setOpenField] = useState(true);
   const [openChecklist, setOpenChecklist] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [openQuantifier, setOpenQuantifier] = useState(false);
   const [openSensorBoards, setOpenSensorBoards] = useState(false);
   const [openFieldReports, setOpenFieldReports] = useState(false);
   const [openData, setOpenData] = useState(false);
   const [openFieldSafety, setOpenFieldSafety] = useState(false);

   const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
   };

   const handleClickSB = () => {
      setSelectedIndex(4);
      setOpenSensorBoards(!openSensorBoards);
   };

   const handleClickField = () => {
      setSelectedIndex(8);
      setOpenField(!openField);
   };

   const handleClickQuantifier = () => {
      setSelectedIndex(1);
      setOpenQuantifier(!openQuantifier);
   };

   const handleNotesClick = () => {
      setSelectedIndex(9);
      setOpenNotes(!openNotes);
   };

   const handleChecklistClick = () => {
      setSelectedIndex(14);
      setOpenChecklist(!openChecklist);
   };

   const handleFieldReportsClick = () => {
      setSelectedIndex(10);
      setOpenFieldReports(!openFieldReports);
   };

   const handleFieldSafetyClick = () => {
      setSelectedIndex(22);
      setOpenFieldSafety(!openFieldSafety);
   };
   const handleDataClick = () => {
      setOpenData(!openData);
   };
   // eslint-disable-next-line
   useEffect(() => {
      // To change the selected if we use back button or redirect
      let window_location = window.location.href.toString();
      window_location = window_location.split("/");
      if (window_location[3] === "dashboard" && selectedIndex !== 0) {
         setSelectedIndex(0);
      } else if (window_location[3] === "quantifier") {
         if (window_location[4] === "add" && selectedIndex !== 2) {
            setSelectedIndex(2);
         } else if (window_location[4] === "view" && selectedIndex !== 3) {
            setSelectedIndex(3);
         } else if (window_location[4] === "report" && selectedIndex !== 11) {
            setSelectedIndex(11);
         }
      } else if (window_location[3] === "sensor-boards") {
         if (window_location[4] === "add" && selectedIndex !== 5) {
            setSelectedIndex(5);
         } else if (window_location[4] === "view" && selectedIndex !== 6) {
            setSelectedIndex(6);
         }
      } else if (window_location[3] === "field") {
         if (window_location[4] === "notes") {
            if (window_location[5] === "add" && selectedIndex !== 12) {
               setSelectedIndex(12);
            } else if (window_location[5] === "view" && selectedIndex !== 13) {
               setSelectedIndex(13);
            }
         } else if (window_location[4] === "checklist") {
            if (window_location[5] === "add" && selectedIndex !== 15) {
               setSelectedIndex(15);
            } else if (window_location[5] === "view" && selectedIndex !== 16) {
               setSelectedIndex(16);
            }
         } else if (window_location[4] === "reports") {
            if (window_location[5] === "add" && selectedIndex !== 19) {
               setSelectedIndex(19);
            } else if (window_location[5] === "view" && selectedIndex !== 20) {
               setSelectedIndex(20);
            }
         } else if (window_location[4] === "safety") {
            if (window_location[5] === "new-site" && selectedIndex !== 23) {
               setSelectedIndex(23);
            } else if (window_location[5] === "view" && selectedIndex !== 24) {
               setSelectedIndex(24);
            } else if (window_location[5] === "journey-management" && selectedIndex !== 25) {
               setSelectedIndex(25);
            }
         }
      } else if (window_location[3] === "sensor-location" && selectedIndex !== 17) {
         setSelectedIndex(17);
      } else if (window_location[3] === "data") {
         if (window_location[4] === "sensor-location" && selectedIndex !== 17) {
            setSelectedIndex(17);
         } else if (window_location[4] === "packet-loss" && selectedIndex !== 7) {
            setSelectedIndex(7);
         } else if (window_location[4] === "kpi" && selectedIndex !== 21) {
            setSelectedIndex(21);
         }
      } else if (window_location[3] === "equipment" && selectedIndex !== 18) {
         setSelectedIndex(18);
      }
   });
   return (
      <React.Fragment>
         <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => {
               handleListItemClick(event, 0);
               navigate(CONSTANTS.PAGE_DASHBOARD);
            }}
            sx={{
               backgroundColor: selectedIndex === 0 && "#87CEEB!important",
            }}
         >
            <ListItemIcon>
               <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
         </ListItemButton>
         <ListItemButton selected={selectedIndex === 1} onClick={handleClickQuantifier}>
            <ListItemIcon>
               <Task />
            </ListItemIcon>
            <ListItemText primary="Quantifiers" />
            {openQuantifier ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={openQuantifier} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               <ListItemButton
                  selected={selectedIndex === 2}
                  sx={{
                     pl: "20%",
                     backgroundColor: selectedIndex === 2 && "#87CEEB!important",
                  }}
                  onClick={(event) => {
                     handleListItemClick(event, 2);
                     navigate(CONSTANTS.PAGE_QUANTIFIER_ADD);
                  }}
               >
                  <ListItemIcon>
                     <Add />
                  </ListItemIcon>
                  <ListItemText primary="Add" />
               </ListItemButton>
               <ListItemButton
                  selected={selectedIndex === 3}
                  sx={{
                     pl: "20%",
                     backgroundColor: selectedIndex === 3 && "#87CEEB!important",
                  }}
                  onClick={(event) => {
                     handleListItemClick(event, 3);
                     navigate(CONSTANTS.PAGE_QUANTIFIER_VIEW);
                  }}
               >
                  <ListItemIcon>
                     <GridView />
                  </ListItemIcon>
                  <ListItemText primary="View" />
               </ListItemButton>
               <ListItemButton
                  selected={selectedIndex === 11}
                  sx={{
                     pl: "20%",
                     backgroundColor: selectedIndex === 11 && "#87CEEB!important",
                  }}
                  onClick={(event) => {
                     handleListItemClick(event, 11);
                     navigate(CONSTANTS.PAGE_QUANTIFIER_REPORT);
                  }}
               >
                  <ListItemIcon>
                     <BarChart />
                  </ListItemIcon>
                  <ListItemText primary="Report" />
               </ListItemButton>
            </List>
         </Collapse>
         <ListItemButton selected={selectedIndex === 4} onClick={handleClickSB}>
            <ListItemIcon>
               <Sensors />
            </ListItemIcon>
            <ListItemText primary="Sensor Boards" />
            {openSensorBoards ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={openSensorBoards} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               <ListItemButton
                  selected={selectedIndex === 5}
                  sx={{
                     pl: "20%",
                     backgroundColor: selectedIndex === 5 && "#87CEEB!important",
                  }}
                  onClick={(event) => {
                     handleListItemClick(event, 5);
                     navigate(CONSTANTS.PAGE_SENSOR_BOARDS_ADD);
                  }}
               >
                  <ListItemIcon>
                     <Add />
                  </ListItemIcon>
                  <ListItemText primary="Add" />
               </ListItemButton>
               <ListItemButton
                  selected={selectedIndex === 6}
                  sx={{
                     pl: "20%",
                     backgroundColor: selectedIndex === 6 && "#87CEEB!important",
                  }}
                  onClick={(event) => {
                     handleListItemClick(event, 6);
                     navigate(CONSTANTS.PAGE_SENSOR_BOARDS_VIEW);
                  }}
               >
                  <ListItemIcon>
                     <GridView />
                  </ListItemIcon>
                  <ListItemText primary="View" />
               </ListItemButton>
            </List>
         </Collapse>

         <ListItemButton
            selected={selectedIndex === 18}
            onClick={(event) => {
               handleListItemClick(event, 18);
               navigate(CONSTANTS.PAGE_EQUIPMENT_VIEW);
            }}
            sx={{
               backgroundColor: selectedIndex === 18 && "#87CEEB!important",
            }}
         >
            <ListItemIcon>
               <Category />
            </ListItemIcon>
            <ListItemText primary="Equipment View" />
         </ListItemButton>

         <ListItemButton onClick={handleDataClick}>
            <ListItemIcon>
               <Dataset />
            </ListItemIcon>
            <ListItemText primary="Data" />
            {openData ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={openData} timeout="auto" unmountOnExit>
            <List
               component="div"
               disablePadding
               sx={{
                  pl: "10%",
               }}
            >
               <ListItemButton
                  selected={selectedIndex === 7}
                  onClick={(event) => {
                     handleListItemClick(event, 7);
                     navigate(CONSTANTS.PAGE_PACKET_LOSS);
                  }}
                  sx={{
                     backgroundColor: selectedIndex === 7 && "#87CEEB!important",
                  }}
               >
                  <ListItemIcon>
                     <SignalCellularOff />
                  </ListItemIcon>
                  <ListItemText primary="Packet Loss" />
               </ListItemButton>
               <ListItemButton
                  selected={selectedIndex === 17}
                  onClick={(event) => {
                     handleListItemClick(event, 17);
                     navigate(CONSTANTS.PAGE_SENSOR_LOCATION);
                  }}
                  sx={{
                     backgroundColor: selectedIndex === 17 && "#87CEEB!important",
                  }}
               >
                  <ListItemIcon>
                     <AddLocationAlt />
                  </ListItemIcon>
                  <ListItemText primary="Sensor Location" />
               </ListItemButton>
               <ListItemButton
                  selected={selectedIndex === 21}
                  onClick={(event) => {
                     handleListItemClick(event, 21);
                     navigate(CONSTANTS.PAGE_SENSOR_KPI);
                  }}
                  sx={{
                     backgroundColor: selectedIndex === 21 && "#87CEEB!important",
                  }}
               >
                  <ListItemIcon>
                     <FactCheck />
                  </ListItemIcon>
                  <ListItemText primary="KPI" />
               </ListItemButton>
            </List>
         </Collapse>
         <ListItemButton selected={selectedIndex === 8} onClick={handleClickField}>
            <ListItemIcon>
               <Handyman />
            </ListItemIcon>
            <ListItemText primary="Field" />
            {openField ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={openField} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               <ListItemButton
                  sx={{
                     pl: "20%",
                  }}
                  selected={selectedIndex === 9}
                  onClick={handleNotesClick}
               >
                  <ListItemIcon>
                     <Notes />
                  </ListItemIcon>
                  <ListItemText primary="Notes" />
                  {openNotes ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
               <Collapse in={openNotes} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 12}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 12 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 12);
                           navigate(CONSTANTS.PAGE_FIELD_NOTES_ADD);
                        }}
                     >
                        <ListItemIcon>
                           <Add />
                        </ListItemIcon>
                        <ListItemText primary="Add" />
                     </ListItemButton>
                     <ListItemButton
                        selected={selectedIndex === 13}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 13 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 13);
                           navigate(CONSTANTS.PAGE_FIELD_NOTES_VIEW);
                        }}
                     >
                        <ListItemIcon>
                           <GridView />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                     </ListItemButton>
                  </List>
               </Collapse>
               <ListItemButton
                  sx={{
                     pl: "20%",
                  }}
                  selected={selectedIndex === 14}
                  onClick={handleChecklistClick}
               >
                  <ListItemIcon>
                     <CheckBox />
                  </ListItemIcon>
                  <ListItemText primary="CheckList" />
                  {openChecklist ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
               <Collapse in={openChecklist} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 15}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 15 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 15);
                           navigate(CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_ADD);
                        }}
                     >
                        <ListItemIcon>
                           <Add />
                        </ListItemIcon>
                        <ListItemText primary="Add" />
                     </ListItemButton>
                     <ListItemButton
                        selected={selectedIndex === 16}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 16 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 16);
                           navigate(CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_VIEW);
                        }}
                     >
                        <ListItemIcon>
                           <GridView />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                     </ListItemButton>
                  </List>
               </Collapse>
               <ListItemButton
                  sx={{
                     pl: "20%",
                  }}
                  selected={selectedIndex === 10}
                  onClick={handleFieldReportsClick}
               >
                  <ListItemIcon>
                     <BarChart />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {openFieldReports ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
               <Collapse in={openFieldReports} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 19}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 19 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 19);
                           navigate(CONSTANTS.PAGE_FIELD_REPORTS_ADD);
                        }}
                     >
                        <ListItemIcon>
                           <Add />
                        </ListItemIcon>
                        <ListItemText primary="Add" />
                     </ListItemButton>
                     <ListItemButton
                        selected={selectedIndex === 20}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 20 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 20);
                           navigate(CONSTANTS.PAGE_FIELD_REPORTS_VIEW);
                        }}
                     >
                        <ListItemIcon>
                           <GridView />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                     </ListItemButton>
                  </List>
               </Collapse>

               <ListItemButton
                  sx={{
                     pl: "20%",
                  }}
                  selected={selectedIndex === 22}
                  onClick={handleFieldSafetyClick}
               >
                  <ListItemIcon>
                     <SafetyCheck />
                  </ListItemIcon>
                  <ListItemText primary="Safety" />
                  {openFieldSafety ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
               <Collapse in={openFieldSafety} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 23}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 23 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 23);
                           navigate(CONSTANTS.PAGE_FIELD_SAFETY_NEW_SITE);
                        }}
                     >
                        <ListItemIcon>
                           <OpenInNew />
                        </ListItemIcon>
                        <ListItemText primary="New Site" />
                     </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 25}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 25 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 25);
                           navigate(CONSTANTS.PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT);
                        }}
                     >
                        <ListItemIcon>
                           <DriveEta />
                        </ListItemIcon>
                        <ListItemText primary="Journey Man.." />
                     </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                     <ListItemButton
                        selected={selectedIndex === 24}
                        sx={{
                           pl: "30%",
                           backgroundColor: selectedIndex === 24 && "#87CEEB!important",
                        }}
                        onClick={(event) => {
                           handleListItemClick(event, 24);
                           navigate(CONSTANTS.PAGE_FIELD_SAFETY_VIEW);
                        }}
                     >
                        <ListItemIcon>
                           <GridView />
                        </ListItemIcon>
                        <ListItemText primary="View" />
                     </ListItemButton>
                  </List>                  
               </Collapse>
            </List>
         </Collapse>
      </React.Fragment>
   );
}


import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import React, { useState } from "react";
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import KeyIcon from '@mui/icons-material/Key';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import LocalStorage from "../Login/local-storage";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export default function AddNotes(props) {
    let ls = new LocalStorage();
    let [data, setData] = useState(props.data)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [noPassword, setNoPassword] = useState(true)
    const [siteData] = useState(props.siteData)
    const username = ls.getLocalStorageItemZoho().user_name
    const user_id = ls.getLocalStorageItemZoho().user_id

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        if (name === 'field_persons' || name === 'power') {
            setData(prev => ({
                ...prev,
                [name]: typeof value === 'string' ? value.split(',') : value
            }))
        } else {
            if (name === 'site') {
                let new_data = siteData.find((item) => item.Name === value)
                setData(prev => ({
                    ...prev,
                    'site': value,
                    'distance_travelled': new_data.distance_to_site ? new_data.distance_to_site : 0
                }))
            }
            else {
                setData(prev => ({
                    ...prev,
                    [name]: value
                }))
            }
        }
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        data.user_name = username
        data.user_id = user_id
        sendData(data)
    }

    function getHoursDiff(startDate, endDate) {
        const msInHour = 1000 * 60 * 60;
        return (Math.abs(endDate - startDate) / msInHour).toFixed(2);
    }

    async function sendData(payloadSubmit) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_UPDATE_API}`, payloadSubmit, { headers })
            .then(() => {
                setLoading(false)
                props.handleDialogClose()
                props.getNotes(props.token, true)
            })
    }
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Grid container rowSpacing={5} columnSpacing={3} mt={2} alignItems="center" justify="center">
                    <Grid item xs={10}>
                        <TextField margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handleChangePassword}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid>
                        <Button variant="contained" onClick={() => {
                            if (password === process.env.REACT_APP_EDIT_PASSWORD) {
                                setNoPassword(false)
                            }
                        }} size="large" color='primary' startIcon={<KeyIcon />}>Unlock</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{
                            width: "50%"
                        }}>
                            <InputLabel id="site-dropdown-label-notes">Site</InputLabel>
                            <Select
                                required
                                id="site-select-notes"
                                name="site"
                                labelId="site-dropdown-label-notes"
                                value={data.site}
                                label="Site"
                                onChange={handleChange}
                                disabled={noPassword}
                            >
                                {siteData && siteData.map(data =>
                                    <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{
                            width: "50%"
                        }}>
                            <InputLabel id="field-notes-type-dropdown">Type</InputLabel>
                            <Select
                                required
                                id="field-notes-type"
                                name="type"
                                labelId="field-notes-type-dropdown"
                                value={data.type}
                                label="Type"
                                onChange={handleChange}
                                disabled={noPassword}
                            >
                                <MenuItem value={'quantifier'} key={1}>{'Quantifier'}</MenuItem>
                                <MenuItem value={'distributor'} key={2}>{'Distributor'}</MenuItem>
                                <MenuItem value={'quantifier/distributor'} key={2}>{'Both'}</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                    {/* {
                        (data.type === 'quantifier' || data.type === 'quantifier/distributor') && */}
                    <React.Fragment>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    required
                                    label="Date of Visit"
                                    value={data.date}
                                    disabled={noPassword}
                                    onChange={(newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            date: new Date(newValue.startOf('day')),
                                            time_arrival: new Date(newValue.startOf('day')).setHours(9),
                                            time_departure: new Date(newValue.startOf('day')).setHours(16)
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        sx={{
                                            width: "50%"
                                        }} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    required
                                    label="Time of Site Arrival"
                                    value={data.time_arrival}
                                    disabled={noPassword}
                                    onChange={(newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            time_arrival: new Date(newValue),
                                            hours: getHoursDiff(new Date(newValue), data.time_departure)
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        sx={{
                                            width: "50%"
                                        }} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    required
                                    label="Time of Site Departure"
                                    value={data.time_departure}
                                    disabled={noPassword}
                                    onChange={(newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            time_departure: new Date(newValue),
                                            hours: getHoursDiff(data.time_arrival, new Date(newValue))
                                        }))

                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        sx={{
                                            width: "50%"
                                        }} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="field-notes-reason-dropdown">Reason</InputLabel>
                                <Select
                                    required
                                    id="field-notes-reason"
                                    name="reason"
                                    labelId="field-notes-reason-dropdown"
                                    value={data.reason}
                                    label="Reason"
                                    onChange={handleChange}
                                    disabled={noPassword}
                                >
                                    <MenuItem value={'install'} key={1}>{'Install'}</MenuItem>
                                    <MenuItem value={'maintenance'} key={2}>{'Maintenance'}</MenuItem>
                                    <MenuItem value={'debrief'} key={3}>{'Debrief'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="demo-multiple-name-label">Field Personal</InputLabel>
                                <Select
                                    multiple
                                    id="demo-multiple-name"
                                    labelId="demo-multiple-name-label"
                                    name="field_persons"
                                    value={data.field_persons}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Field Personal" />}
                                    disabled={noPassword}
                                >
                                    {CONSTANTS.FIELD_PERSONAL.map((name, id) => (
                                        <MenuItem
                                            key={id}
                                            value={name.toLocaleUpperCase()}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="scope" name='scope' label="Scope of Work" variant="outlined"
                                multiline
                                value={data.scope}
                                onChange={handleChange}
                                disabled={noPassword}
                                sx={{
                                    width: "50%"
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="general_notes" name='general_notes' label="General Notes" variant="outlined"
                                multiline
                                value={data.general_notes}
                                disabled={noPassword}
                                onChange={handleChange}
                                sx={{
                                    width: "50%"
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="field-notes-safety-dropdown">Safety Forms Filled</InputLabel>
                                <Select
                                    required
                                    id="field-notes-safety-select"
                                    name="safety"
                                    labelId="field-notes-safety-dropdown"
                                    value={data.safety}
                                    label="Safety Forms Filled"
                                    onChange={handleChange}
                                    disabled={noPassword}
                                >
                                    <MenuItem value={'YES'} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={'NO'} key={2}>{'No'}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.safety === "NO" &&
                            <Grid item xs={4}>
                                <TextField id="reason" name='reason_safety' label="Reason No Safety Form" variant="outlined"
                                    multiline
                                    value={data.reason_safety}
                                    onChange={handleChange}
                                    disabled={noPassword}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                        }
                        {
                            data.reason === "install" &&
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <FormControl sx={{
                                        width: "50%"
                                    }}>
                                        <InputLabel id="field-notes-power-dropdown">Power</InputLabel>
                                        <Select
                                            multiple
                                            id="field-notes-power"
                                            name="power"
                                            labelId="field-notes-power-dropdown"
                                            value={data.power}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Power" />}
                                            disabled={noPassword}
                                        >
                                            <MenuItem value={'underground'} key={1}>{'Underground'}</MenuItem>
                                            <MenuItem value={'solar'} key={2}>{'Solar'}</MenuItem>
                                            <MenuItem value={'hardwired'} key={3}>{'Hardwired'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="power_notes" name='power_notes' label="Power Notes" variant="outlined"
                                        value={data.power_notes}
                                        multiline
                                        onChange={handleChange}
                                        disabled={noPassword}
                                        sx={{
                                            width: "50%"
                                        }}
                                    />
                                </Grid>
                                {
                                    (data.stick_up_lengths && data.stick_up_lengths.length) ?
                                        data.stick_up_lengths.map((value, idx) =>
                                            <Grid key={idx + 1} item xs={4}>
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        id={`${idx}-quantifier`}
                                                        label={"Quantifier"}
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                        disabled={noPassword}
                                                        value={value.quantifier}
                                                        onChange={(e) => {
                                                            let stick_up_lengths = [...data.stick_up_lengths];
                                                            stick_up_lengths[idx].quantifier = e.target.value
                                                            setData(prev => ({
                                                                ...prev,
                                                                stick_up_lengths
                                                            }))
                                                        }}
                                                    />
                                                    <Stack direction="row" spacing={2}>
                                                        <TextField id={`${idx}-length`} label="Stick Up Length" variant="outlined" type={'number'}
                                                            sx={{
                                                                width: "35%",
                                                            }}
                                                            value={value.stick_up_length}
                                                            disabled={noPassword}
                                                            onChange={(e) => {
                                                                let stick_up_lengths = [...data.stick_up_lengths];
                                                                stick_up_lengths[idx].stick_up_length = parseFloat(e.target.value)
                                                                setData(prev => ({
                                                                    ...prev,
                                                                    stick_up_lengths
                                                                }))
                                                            }}
                                                        />
                                                        <AddCircleIcon color='primary' sx={{
                                                            width: "15%",
                                                            alignSelf: 'center',
                                                            cursor: 'pointer',
                                                            color: noPassword ? "grey" : "primary"
                                                        }}
                                                            disabled={noPassword}
                                                            onClick={() => {
                                                                if (!noPassword) {
                                                                    let stick_up_lengths = [...data.stick_up_lengths];
                                                                    stick_up_lengths.push({})
                                                                    setData(prev => ({
                                                                        ...prev,
                                                                        stick_up_lengths
                                                                    }))
                                                                }
                                                            }}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>) :
                                        <React.Fragment />
                                }
                                {
                                    (data.power && data.power.includes('underground')) &&
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <TextField id="power_flushmount" name='power_flushmount' label="Flushmount Size" variant="outlined"
                                                multiline
                                                value={data.power_flushmount}
                                                onChange={handleChange}
                                                sx={{
                                                    width: "50%"
                                                }}
                                                disabled={noPassword}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    (data.power && data.power.includes('solar')) &&
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <TextField id="solarpanel_distance" name='solarpanel_distance' label="Distance to Solar Panel" variant="outlined"
                                                multiline
                                                value={data.power_flushmount}
                                                onChange={handleChange}
                                                sx={{
                                                    width: "50%"
                                                }}
                                                disabled={noPassword}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    (data.boreholes && data.boreholes.length) ?
                                        data.boreholes.map((value, idx) =>
                                            <Grid key={idx + 1} item xs={4}>
                                                <Stack spacing={2}>
                                                    <TextField
                                                        id={`${idx}-borehole`}
                                                        label={"Borehole/Monitoring Well"}
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                        disabled={noPassword}
                                                        value={value.borehole}
                                                        onChange={(e) => {
                                                            let boreholes = [...data.boreholes];
                                                            boreholes[idx].borehole = e.target.value
                                                            setData(prev => ({
                                                                ...prev,
                                                                boreholes
                                                            }))
                                                        }}
                                                    />

                                                    <TextField id={`${idx}-depth`} label="Depth" variant="outlined" type={'number'}
                                                        onChange={(e) => {
                                                            let boreholes = [...data.boreholes];
                                                            boreholes[idx].depth = parseFloat(e.target.value)
                                                            setData(prev => ({
                                                                ...prev,
                                                                boreholes
                                                            }))
                                                        }}
                                                        disabled={noPassword}
                                                        value={value.depth}
                                                        sx={{
                                                            width: "50%",
                                                        }}
                                                    />
                                                    <Stack direction="row" spacing={2}>
                                                        <TextField id={`${idx}-depth_to_water`} label="Depth to Water" variant="outlined" type={'number'}
                                                            onChange={(e) => {
                                                                let boreholes = [...data.boreholes];
                                                                boreholes[idx].depth_to_water = parseFloat(e.target.value)
                                                                setData(prev => ({
                                                                    ...prev,
                                                                    boreholes
                                                                }))
                                                            }}
                                                            disabled={noPassword}
                                                            value={value.depth_to_water}
                                                            sx={{
                                                                width: "35%",
                                                            }}
                                                        />
                                                        <AddCircleIcon color='primary' sx={{
                                                            width: "15%",
                                                            alignSelf: 'center',
                                                            cursor: 'pointer',
                                                            color: noPassword ? "grey" : "primary"
                                                        }}
                                                            disabled={noPassword}
                                                            onClick={() => {
                                                                if (!noPassword) {
                                                                    let boreholes = [...data.boreholes];
                                                                    boreholes.push({})
                                                                    setData(prev => ({
                                                                        ...prev,
                                                                        boreholes
                                                                    }))
                                                                }
                                                            }}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Grid>) : <React.Fragment></React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                    {/* } */}
                    {
                        (data.type === 'distributor' || data.type === 'quantifier/distributor') &&
                        <React.Fragment>
                            <Grid item xs={4}>
                                <TextField id="distributor_location" name='distributor_location' label="Distributor Location" variant="outlined"
                                    multiline
                                    placeholder='Describe Location'
                                    value={data.distributor.location}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                location: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="water_connection" name='water_connection' label="Water Connection" variant="outlined"
                                    placeholder='Tap, Well, Notes !'
                                    multiline
                                    value={data.distributor.water_connection}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                water_connection: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="injection_point" name='injection_point' label="Injection Point" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={data.distributor.injection_point}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                injection_point: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            {
                                (data.reason === 'install') &&
                                <React.Fragment>
                                    <Grid item xs={4}>
                                        <FormControl sx={{
                                            width: "50%"
                                        }}>
                                            <InputLabel id="field-notes-power-dropdown">Power (Distributor)</InputLabel>
                                            <Select
                                                multiple
                                                id="field-notes-power"
                                                name="power"
                                                labelId="field-notes-power-dropdown"
                                                value={data.distributor.power}
                                                disabled={noPassword}
                                                onChange={(e) => {
                                                    let { value } = e.target
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            power: typeof value === 'string' ? value.split(',') : value
                                                        }
                                                    }))
                                                }}
                                                input={<OutlinedInput label="Power (Distributor)" />}
                                            >
                                                <MenuItem value={'underground'} key={1}>{'Underground'}</MenuItem>
                                                <MenuItem value={'solar'} key={2}>{'Solar'}</MenuItem>
                                                <MenuItem value={'hardwired'} key={3}>{'Hardwired'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="power_notes" name='power_notes' label="Power Notes" variant="outlined"
                                            value={data.distributor.power_notes}
                                            multiline
                                            disabled={noPassword}
                                            onChange={(e) => {
                                                let { value } = e.target
                                                setData(prev => ({
                                                    ...prev,
                                                    distributor: {
                                                        ...prev.distributor,
                                                        power_notes: value
                                                    }
                                                }))
                                            }}
                                            sx={{
                                                width: "50%"
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={4}>
                                <TextField id="carbon_bags" name='carbon_bags' label="Carbon Bags" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={data.distributor.carbon_bags}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                carbon_bags: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="equipment_used" name='equipment_used' label="Equipment Used" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={data.distributor.equipment_used}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                equipment_used: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="settings" name='settings' label="Distributor Settings" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={data.distributor.settings}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                settings: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="triply_tank" name='triply_tank' label="Triply Tank" variant="outlined"
                                    multiline
                                    placeholder='Measurement'
                                    value={data.distributor.triply_tank}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                triply_tank: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="iron_citrate" name='iron_citrate' label="Iron Citrate" variant="outlined"
                                    multiline
                                    placeholder='Measurement'
                                    value={data.distributor.iron_citrate}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                iron_citrate: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="injection_settings" name='injection_settings' label="Injection Settings" variant="outlined"
                                    multiline
                                    placeholder='Timer for 6 hours on off?'
                                    value={data.distributor.injection_settings}
                                    disabled={noPassword}
                                    onChange={(e) => {
                                        setData(prev => ({
                                            ...prev,
                                            distributor: {
                                                ...prev.distributor,
                                                injection_settings: e.target.value
                                            }
                                        }))
                                    }}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        (data.tasks && data.tasks.length) ?
                            data.tasks.map((value, idx) =>
                                <Grid key={idx + 1} item xs={4}>
                                    <Stack spacing={2}>
                                        <TextField
                                            multiline
                                            id={`${idx}-Task Completed`}
                                            label={"Task Completed"}
                                            style={{
                                                width: "50%",
                                            }}
                                            disabled={noPassword}
                                            value={value.task}
                                            onChange={(e) => {
                                                let tasks = [...data.tasks];
                                                tasks[idx].task = e.target.value;
                                                setData(prev => ({
                                                    ...prev,
                                                    tasks
                                                }))
                                            }}
                                        />
                                        <Stack direction="row" spacing={2}>
                                            <TextField id={`${idx}-Time Spent`} label="Time Spent" variant="outlined" type={'number'}
                                                sx={{
                                                    width: "35%",
                                                }}
                                                value={value.time_spent}
                                                disabled={noPassword}
                                                onChange={(e) => {
                                                    let tasks = [...data.tasks];
                                                    tasks[idx].time_spent = parseFloat(e.target.value)
                                                    setData(prev => ({
                                                        ...prev,
                                                        tasks
                                                    }))
                                                }}
                                            />
                                            <AddCircleIcon color='primary' sx={{
                                                width: "15%",
                                                alignSelf: 'center',
                                                cursor: 'pointer',
                                                color: noPassword ? "grey" : "primary"
                                            }}
                                                disabled={noPassword}
                                                onClick={() => {
                                                    if (!noPassword) {
                                                        let tasks = [...data.tasks];
                                                        tasks.push({})
                                                        setData(prev => ({
                                                            ...prev,
                                                            tasks
                                                        }))
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>) : <React.Fragment></React.Fragment>
                    }

                    <Grid item xs={4}>
                        <TextField id="special_tools" name='special_tools' label="Special Tools Needed" variant="outlined"
                            value={data.special_tools}
                            multiline
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                            disabled={noPassword}
                        />
                    </Grid>
                    {
                        (data.issues && data.issues.length) ?
                            data.issues.map((value, idx) =>
                                <Grid key={idx} item xs={4}>
                                    <Stack spacing={2} direction="row"
                                        alignItems="center">
                                        <TextField
                                            multiline
                                            disabled={noPassword}
                                            id={`${idx}-issue`}
                                            label={"Issues"}
                                            value={value}
                                            onChange={(e) => {
                                                let issues = [...data.issues];
                                                issues[idx] = e.target.value;
                                                setData(prev => ({
                                                    ...prev,
                                                    issues
                                                }))
                                            }}
                                        />
                                        <AddCircleIcon color='primary' sx={{
                                            cursor: 'pointer',
                                            color: noPassword ? "grey" : "primary"
                                        }}
                                            disabled={noPassword}
                                            onClick={(e) => {
                                                if (!noPassword) {
                                                    let issues = [...data.issues];
                                                    issues.push('')
                                                    setData(prev => ({
                                                        ...prev,
                                                        issues
                                                    }))
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Grid>) :
                            <React.Fragment></React.Fragment>
                    }
                    {
                        (data.next_steps && data.next_steps.length) ?
                            data.next_steps.map((value, idx) =>
                                <Grid key={idx} item xs={4}>
                                    <Stack spacing={2} direction="row"
                                        alignItems="center">
                                        <TextField
                                            multiline
                                            id={`${idx}-next_steps`}
                                            label={"Next Steps"}
                                            value={value}
                                            disabled={noPassword}
                                            onChange={(e) => {
                                                let next_steps = [...data.next_steps];
                                                next_steps[idx] = e.target.value;
                                                setData(prev => ({
                                                    ...prev,
                                                    next_steps
                                                }))
                                            }}
                                        />
                                        <AddCircleIcon color='primary' sx={{
                                            cursor: 'pointer',
                                            color: noPassword ? "grey" : "primary"
                                        }}
                                            disabled={noPassword}
                                            onClick={() => {
                                                if (!noPassword) {
                                                    let next_steps = [...data.next_steps];
                                                    next_steps.push('')
                                                    setData(prev => ({
                                                        ...prev,
                                                        next_steps
                                                    }))
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Grid>) : <React.Fragment></React.Fragment>
                    }
                    <Grid item xs={4}>
                        <TextField id="hours" name='hours' label="Hours on Site" variant="outlined"
                            value={data.hours}
                            disabled={true}
                            InputProps={{
                                rows: 1
                            }}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="distance_travelled" name='distance_travelled' label="Distance to Site (KM)" variant="outlined" type={'number'}
                            value={data.distance_travelled}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                            disabled={noPassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            <Typography variant="h6">
                                Notes Added by
                            </Typography>
                            <Typography variant="h6" color={'red'}>
                                {data.user_name ? data.user_name : '-'}
                            </Typography>
                        </Stack>
                    </Grid>
                    {(data.user_name !== username) &&
                        <Grid item xs={12}>
                            <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                <Typography variant="h6">
                                    Current User
                                </Typography>
                                <Typography variant="h6" color={'red'}>
                                    {username}
                                </Typography>
                            </Stack>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={handleSubmit} size="large" color='primary' startIcon={<CloudUploadIcon />} disabled={props.data === data || noPassword}>Update</Button>
                        <Button variant="contained" onClick={props.handleDialogClose} size="large" color='warning' startIcon={<CancelIcon />} sx={{ ml: '5%' }}>Cancel</Button>
                    </Grid>
                </Grid>
            }
        </React.Fragment >
    )
}
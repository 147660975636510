import axios from 'axios';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import GoogleIcon from '@mui/icons-material/Google';
import OutlinedInput from '@mui/material/OutlinedInput';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

let utmObj = require('utm-latlng');

export default function Sensor_Location(props) {
    const [site, setSite] = useState("");
    const [loading, setLoading] = useState(false);
    const [siteData, setSiteData] = useState(null);
    const [rows, setRows] = useState([]);
    let columns = [
        {
            field: 'site',
            headerName: 'Site',
            flex: 1,
        },
        {
            field: 'quantifier',
            headerName: 'Quantifier Name',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'coordinates',
            headerName: 'Coordinates',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            disableExport: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0.3} sx={{ cursor: "Pointer" }} onClick={(e) =>
                        window.open(`https://www.google.com/maps/search/${params.row.coordinates}`, '_blank')}>
                        <GoogleIcon sx={{ color: 'blue' }} />
                        <NorthEastIcon sx={{ color: 'green' }} />
                    </Stack>
                );
            },
        },
        {
            field: 'easting',
            headerName: 'Easting',
        },
        {
            field: 'northing',
            headerName: 'Northing'
        },
        {
            field: 'utm_zone',
            headerName: 'UTM Zone',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'sensor_depth',
            headerName: 'Sensor Depths',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0.1}>
                        {params.row.sensor_depth.map((item, key) =>
                            <Chip key={key} label={item} />
                        )}
                    </Stack>
                );
            },
        },
    ]
    const mapSiteData = useCallback(async (data) => {
        const utm = new utmObj();
        setRows(data.quantifier_info.map((item, key) => {
            let new_obj = {}
            let latlng = { Easting: '-', Northing: '-' }
            if (item.loc.coordinates && item.loc.coordinates.length) {
                latlng = utm.convertLatLngToUtm(item.loc.coordinates[0], item.loc.coordinates[1], 2)
            }
            new_obj.id = key
            new_obj.site = data.fullName
            new_obj.quantifier = item.Name
            new_obj.coordinates = item.loc.coordinates
            new_obj.sensor_depth = data.Sensor_depths[item.Name]
            new_obj.utm_zone = latlng.ZoneNumber
            new_obj.easting = latlng.Easting
            new_obj.northing = latlng.Northing
            return new_obj
        }))
    }, [])
    const handleChange = (event) => {
        const { target: { value } } = event;
        let filtered_site = siteData.find(item => item.Name === value)
        if (filtered_site) {
            mapSiteData(filtered_site)
            setSite(value)
        }
    };

    const getSites = useCallback(async (token) => {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                setLoading(false)
                setSiteData(data)
                mapSiteData(data[0])
                setSite(data[0].Name)
            })
    }, [mapSiteData])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: `Sensor-Location-${site}`,
                }} />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        if (props.token) {
            setLoading(true)
            getSites(props.token)
        }
    }, [props.token, getSites])

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12}>
                    <InputLabel id="site-dropdown-label">Site</InputLabel>
                    <Select
                        name="site"
                        labelId="site-dropdown-label"
                        id="site-dropdown-select"
                        label="Site"
                        value={site}
                        onChange={handleChange}
                        sx={{ width: "75%" }}
                        input={<OutlinedInput label="Sites" />}
                    >
                        {siteData && siteData.map(data =>
                            <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={12} mt={2}>
                    {
                        (rows && rows.length) ?
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>

            </Grid>}
        </React.Fragment>
    )
}
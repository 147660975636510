import axios from "axios";
import moment from "moment/moment";
import Title from "../Dashboard/Title";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../helpers/constants";
import LocalStorage from "../Login/local-storage";
import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AddCircle, PhotoCamera, CloudUpload, Clear, CloudDownload } from "@mui/icons-material";
import {
   Link,
   Grid,
   Stack,
   Button,
   Select,
   MenuItem,
   TextField,
   ImageList,
   InputLabel,
   Typography,
   FormControl,
   ImageListItem,
   CircularProgress,
} from "@mui/material";

export default function SiteSpecificSafety(props) {
   let ls = new LocalStorage();
   const [siteData, setSiteData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [isDetail, setIsDetail] = useState(false);
   const [data, setData] = useState({
      site_name: "Annaheim",
      site_name_search: "ANNAHEIM",
      type: CONSTANTS.SAFETY.NEW_SITE,
      datetime_on_site: new Date(moment().hours(9)),
      latitude: "",
      longitude: "",
      location_link: "",
      physical_hazards: [""],
      chemical_hazards: [""],
      ergonomic_hazards: [""],
      health_hazards: [""],
      safety_hazards: [""],
      psychosocial_hazards: [""],
      mitigation_strategies: [""],
      user_name: ls.getLocalStorageItemZoho().user_name,
      user_id: ls.getLocalStorageItemZoho().user_id,
   });
   const navigate = useNavigate();

   async function getSites(token) {
      const headers = {
         authorization: "Bearer " + token,
      };
      await axios
         .get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, {
            headers,
         })
         .then((response) => {
            let data = response.data;
            data.sort((a, b) => {
               if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
               if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
               return 0;
            });
            setSiteData(data);
            setData((prev) => ({
               ...prev,
               site_name: data[0].Name,
            }));
         });
   }
   function pasteImage(event) {
      if (event.clipboardData && event.clipboardData.files.length) {
         let new_images = [];
         const {
            clipboardData: { files },
         } = event;
         const {
            target: { name },
         } = event;
         if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
               if (files[i].type.includes("image")) new_images.push(files[i]);
            }
            setData((prev) => ({
               ...prev,
               [name]: new_images,
            }));
         }
      }
   }
   const downloadImageAWS = (data_key) => {
      setLoading(true);
      let payload = {
         key: props.data[data_key],
         type: props.data.type,
         _id: props.data._id,
      };
      getDataAWS(payload, data_key);
   };

   function uploadData(event) {
      event.preventDefault();
      const {
         target: { files },
      } = event;
      const {
         target: { name },
      } = event;
      let new_images = [];
      if (files && files.length) {
         for (let i = 0; i < files.length; i++) {
            new_images.push(files[i]);
         }
         setData((prev) => ({
            ...prev,
            [name]: new_images,
         }));
      }
   }

   async function getDataAWS(payload, data_key) {
      console.log(data_key);
      const headers = {
         authorization: "Bearer " + props.token,
      };
      await axios
         .post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SAFETY_API.IMAGES}`, payload, { headers })
         .then((result) => {
            setData((prev) => ({
               ...prev,
               [`${data_key}_aws`]: result.data.data,
            }));
            setLoading(false);
         });
   }

   async function uploadtoS3() {
      let keys = ["site_pictures"];
      let locations = ["Safety/New-Site"];
      let date = new Date();
      let datestring =
         date.getDate() +
         "-" +
         (date.getMonth() + 1) +
         "-" +
         date.getFullYear() +
         " " +
         date.getHours() +
         ":" +
         date.getMinutes() +
         ":" +
         date.getSeconds();

      let resultant_links = [];
      let url = ''
      for (let k = 0; k < keys.length; k++) {
         if (data[keys[k]] && data[keys[k]].length) {
            for (let i = 0; i < data[keys[k]].length; i++) {
               let type = data[keys[k]][i].type;
               type = type.split("/");
               type = type[1];
               let params = {
                  Bucket: process.env.REACT_APP_BUCKET_NOTES,
                  Key: `${data.site_name}/${locations[k]}/${datestring}-${i}.${type}`,
                  Body: data[keys[k]][i],
               };
               // setting the specific index image's link
               resultant_links.push(`${data.site_name}/${locations[k]}/${datestring}-${i}.${type}`);
               url = await getSignedUrl(params.Key)
               await fetch(url, {
                   method: 'PUT',
                   body: params.Body,
                   headers: {
                       'Content-Type': type, // Ensures correct file type is set
                   },
               });
               // await s3.upload(params).promise();
            }
            data[keys[k]] = resultant_links;
            resultant_links = [];
         }
      }
   }

   async function getSignedUrl(key) {
      const headers = {
          authorization: "Bearer " + props.token,
      };
      const result = await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_GET_SIGNED_URL}`, { url: key }, { headers })
      return result.data.url
  }

   async function sendData() {
      const headers = {
         authorization: "Bearer " + props.token,
      };
      await axios
         .post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SAFETY_API.NEW_FORM_ADD}`, data, {
            headers,
         })
         .then(() => {
            setLoading(false);
            navigate(CONSTANTS.PAGE_FIELD_SAFETY_VIEW);
         });
   }
   const handleSubmit = (event) => {
      event.preventDefault();
      setLoading(true);
      uploadtoS3().then(() => {
         sendData(data);
      });
   };
   useEffect(() => {
      if (props.data && props.data !== data) {
         setData(props.data);
         setIsDetail(true);
      }
      if (!data.location_link && !props.data) {
         navigator.geolocation.getCurrentPosition((position) => {
            setData((prev) => ({
               ...prev,
               latitude: position.coords.latitude,
               longitude: position.coords.longitude,
               location_link: `https://www.google.com/maps/search/${position.coords.latitude}, ${position.coords.longitude}`,
            }));
         });
      }
      if (!siteData.length && !props.data) {
         setLoading(true);
         getSites(props.token).then(() => setLoading(false));
      }
      // eslint-disable-next-line
   }, []);
   return (
      <React.Fragment>
         <Title>{props.title}</Title>
         <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            mt={2}
            alignItems="center"
            justify="center"
         >
            {loading && (
               <Grid item xs={12} m={5}>
                  <CircularProgress />
               </Grid>
            )}
            {!loading && (
               <>
                  <Grid item xs={12}>
                     <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                           label="Datetime on Site"
                           value={data.datetime_on_site}
                           onChange={(newValue) => {
                              setData((prev) => ({
                                 ...prev,
                                 datetime_on_site: newValue ? new Date(newValue) : "",
                              }));
                           }}
                           disabled={isDetail}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                     <Link
                        component="button"
                        variant="button"
                        onClick={() => {
                           window.open(data.location_link);
                        }}
                     >
                        {`Google Map of ${isDetail ? "Site" : "Current"} Location`}
                     </Link>
                  </Grid>
                  <Grid item xs={6}>
                     <FormControl
                        sx={{
                           width: "50%",
                        }}
                     >
                        {!isDetail && <InputLabel id="site-dropdown-label-safety">Site</InputLabel>}
                        {!isDetail && (
                           <Select
                              // required
                              id="site-select-safety"
                              name="site"
                              labelId="site-dropdown-label-safety"
                              value={data.site_name}
                              label="Site"
                              onChange={(e) => {
                                 setData((prev) => ({
                                    ...prev,
                                    site_name: e.target.value,
                                    site_name_search: e.target.value.toLocaleUpperCase(),
                                 }));
                              }}
                           >
                              {siteData.length
                                 ? siteData.map((data) => (
                                      <MenuItem value={data.Name} key={data._id}>
                                         {data.fullName}
                                      </MenuItem>
                                   ))
                                 : []}
                           </Select>
                        )}
                        {isDetail && (
                           <TextField
                              id="site-select-safety"
                              name="site"
                              value={data.site_name || ""}
                              disabled={true}
                              label="Site"
                           />
                        )}
                     </FormControl>
                  </Grid>
                  {!isDetail && (
                     <Grid item xs={6}>
                        <Stack spacing={2}>
                           <TextField
                              multiline
                              placeholder={
                                 data.site_pictures && data.site_pictures.length
                                    ? ` Selected: ${
                                         data.site_pictures.length
                                      }\n ${data.site_pictures.map((value) => value.name)}`
                                    : "Detailed Description or Picture"
                              }
                              label={"Muster Point/Location(s)"}
                              style={{
                                 width: "50%",
                                 alignSelf: "center",
                              }}
                              color="secondary"
                              variant="filled"
                              focused
                              name="site_pictures"
                              onPaste={pasteImage}
                           />
                           <Button
                              variant="outlined"
                              component="label"
                              startIcon={<PhotoCamera />}
                              style={{
                                 width: "50%",
                                 alignSelf: "center",
                              }}
                           >
                              Muster Point/Location(s)
                              <input
                                 type="file"
                                 multiple
                                 accept="image/*"
                                 name="site_pictures"
                                 onChange={uploadData}
                                 hidden
                              />
                           </Button>
                        </Stack>
                     </Grid>
                  )}
                  {isDetail &&
                  props.data.site_pictures &&
                  props.data.site_pictures.length &&
                  !data.site_pictures_aws ? (
                     <Grid item xs={6}>
                        <Stack spacing={2}>
                           <TextField
                              multiline
                              placeholder={` Selected: ${props.data.site_pictures.length}`}
                              label={"Selected Site Picture(s)"}
                              inputProps={{ readOnly: true }}
                              style={{
                                 width: "50%",
                                 alignSelf: "center",
                              }}
                              color="secondary"
                              variant="filled"
                              focused
                           />
                           <Button
                              variant="outlined"
                              component="label"
                              onClick={() => downloadImageAWS("site_pictures")}
                              startIcon={<CloudDownload />}
                              style={{
                                 width: "50%",
                                 alignSelf: "center",
                              }}
                           >
                              Load Site Pic(s)
                           </Button>
                        </Stack>
                     </Grid>
                  ) : (
                     <React.Fragment></React.Fragment>
                  )}
                  {data.site_pictures_aws && data.site_pictures_aws.length ? (
                     <React.Fragment>
                        <Typography
                           component="h2"
                           variant="body1"
                           color="primary"
                           gutterBottom
                           sx={{
                              color: "black",
                              marginLeft: "2%",
                           }}
                        />
                        {/* </Typography> */}
                        <ImageList cols={3}>
                           {data.site_pictures_aws.map((item, key) => (
                              <ImageListItem key={key}>
                                 <img
                                    src={`${item}`}
                                    alt={"Muster Point Pictures"}
                                    loading="lazy"
                                    style={{ objectFit: "contain" }}
                                 />
                              </ImageListItem>
                           ))}
                        </ImageList>
                     </React.Fragment>
                  ) : (
                     <React.Fragment />
                  )}
                  {data.physical_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={1}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-physical_hazards`}
                              label={"List any Physical Hazard"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Physical hazards examples are exposure to items like noise, vibration, radiation, heat, cold, air quality, etc."
                              onChange={(e) => {
                                 let physical_hazards = [...data.physical_hazards];
                                 physical_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    physical_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let physical_hazards = [...data.physical_hazards];
                                 physical_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    physical_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.chemical_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-chemical_hazards`}
                              label={"List any Chemical Hazards"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Chemical hazards examples are exposure to items like corrosive materials, fuels, cleaners, etc."
                              onChange={(e) => {
                                 let chemical_hazards = [...data.chemical_hazards];
                                 chemical_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    chemical_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let chemical_hazards = [...data.chemical_hazards];
                                 chemical_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    chemical_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.ergonomic_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-ergonomic_hazards`}
                              label={"List any Ergonomic Hazards"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Ergonomic hazards examples are exposure to items like lifting, pushing, pulling, sitting, lighting, etc."
                              onChange={(e) => {
                                 let ergonomic_hazards = [...data.ergonomic_hazards];
                                 ergonomic_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    ergonomic_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let ergonomic_hazards = [...data.ergonomic_hazards];
                                 ergonomic_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    ergonomic_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.health_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-health_hazards`}
                              label={"List any Health Hazards"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Health hazards examples are exposure to items like biolgical, moulds, diseases, etc."
                              onChange={(e) => {
                                 let health_hazards = [...data.health_hazards];
                                 health_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    health_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let health_hazards = [...data.health_hazards];
                                 health_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    health_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.safety_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-safety_hazards`}
                              label={"List any Safety Hazards"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Safety hazards examples are exposure to items like ladders, machinery, forklift / skidsteer, material handling, slip / trip / fall, etc."
                              onChange={(e) => {
                                 let safety_hazards = [...data.safety_hazards];
                                 safety_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    safety_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let safety_hazards = [...data.safety_hazards];
                                 safety_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    safety_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.psychosocial_hazards.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-psychosocial_hazards`}
                              label={"List any Psychosocial Hazards"}
                              value={value}
                              disabled={isDetail}
                              placeholder="Safety hazards examples are exposure to items like stress, violence, assaulas, etc."
                              onChange={(e) => {
                                 let psychosocial_hazards = [...data.psychosocial_hazards];
                                 psychosocial_hazards[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    psychosocial_hazards,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let psychosocial_hazards = [...data.psychosocial_hazards];
                                 psychosocial_hazards.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    psychosocial_hazards,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}

                  {data.mitigation_strategies.map((value, idx) => (
                     <Grid key={idx} item xs={6}>
                        <Stack
                           spacing={2}
                           direction="row"
                           justifyContent="center"
                           alignItems="center"
                        >
                           <TextField
                              multiline
                              id={`${idx}-mitigation_strategies`}
                              label={"List of controls to any Hazards found above"}
                              value={value}
                              disabled={isDetail}
                              placeholder="What are the risk mitigation strategies? (if any)"
                              onChange={(e) => {
                                 let mitigation_strategies = [...data.mitigation_strategies];
                                 mitigation_strategies[idx] = e.target.value;
                                 setData((prev) => ({
                                    ...prev,
                                    mitigation_strategies,
                                 }));
                              }}
                              style={{ width: "80%" }}
                           />
                           <AddCircle
                              color="primary"
                              sx={{
                                 cursor: "pointer",
                              }}
                              disabled={isDetail}
                              onClick={() => {
                                 let mitigation_strategies = [...data.mitigation_strategies];
                                 mitigation_strategies.push("");
                                 setData((prev) => ({
                                    ...prev,
                                    mitigation_strategies,
                                 }));
                              }}
                           />
                        </Stack>
                     </Grid>
                  ))}
                  {!isDetail && (
                     <Grid item xs={6}>
                        <Button
                           variant="contained"
                           onClick={handleSubmit}
                           size="large"
                           startIcon={<CloudUpload />}
                        >
                           Submit
                        </Button>
                     </Grid>
                  )}
                  {isDetail && (
                     <Grid item xs={6}>
                        <Button
                           variant="outlined"
                           color="warning"
                           onClick={props.handleDialogClose}
                           size="large"
                           startIcon={<Clear />}
                        >
                           Close
                        </Button>
                     </Grid>
                  )}
               </>
            )}
         </Grid>
      </React.Fragment>
   );
}

import axios from "axios";
import Title from "../Dashboard/Title";
import CONSTANTS from "../../helpers/constants";
import SiteSpecificSafety from "./site_specific_safety";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState, useCallback } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Search, Preview, Clear } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataGrid, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";
import {
   Box,
   Grid,
   Dialog,
   Button,
   TextField,
   DialogContent,
   CircularProgress,
} from "@mui/material";


export default function ViewSafety(props) {
   const [openDetailModal, setOpenDetailModal] = useState(false);
   const [loadMoreSearch, setLoadMoreSearch] = useState(false);
   const [loadMore, setLoadMore] = useState(false);
   const [loading, setLoading] = useState(false);
   const [results, setResults] = useState(null);
   const [search, setSearch] = useState({
      site: "",
      issues: "",
      date_from: new Date().setDate(new Date().getDate() - 5),
      date_to: new Date(),
   });
   const [limit, setLimit] = useState(10);
   const [detailData, setDetailData] = useState({});
   const [searchLimit, setSearchLimit] = useState(10);
   const [total, setTotal] = useState(0);

   let columns = [
      {
         field: "site_name",
         headerName: "Site",
         flex: 1,
      },
      {
         field: "datetime_on_site",
         headerName: "Datetime Field trip",
         valueGetter: ({ value }) =>
            `${
               value ? new Date(value).toLocaleString("en-US", { timeZone: "America/Regina" }) : "-"
            }`,
         flex: 1,
      },
      {
         field: "type",
         headerName: "Type of Form",
         flex: 1,
         valueGetter: ({ value }) => `${value ? value : "-"}`,
      },
      {
         field: "user_name",
         headerName: "Field Person",
         flex: 1,
         valueGetter: ({ value }) => `${value ? value : "-"}`,
      },
      {
         field: "action",
         headerName: "Action",
         sortable: false,
         disableClickEventBubbling: true,
         flex: 1,
         renderCell: (params) => {
            return (
               <Preview
                  id={params.row.id}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                     setDetailData(params.row);
                     setOpenDetailModal(true);
                  }}
               />
            );
         },
      },
      {
         field: "_id",
      },
   ];

   function CustomFooterTotalComponent(props) {
      return (
         <Box m={1} p={1}>
            <b>
               Displaying {results.length} of {total ? total : 0}
            </b>
         </Box>
      );
   }

   const getSafety = useCallback(
      async (token, fromStart) => {
         setLoading(true);
         const headers = {
            authorization: "Bearer " + token,
         };
         let limit_to_put = fromStart ? 10 : limit;
         await axios
            .get(
               `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SAFETY_API.GET.replace(
                  ":limit",
                  limit_to_put
               )}`,
               { headers }
            )
            .then((response) => {
               let temp = limit + 10;
               setLoading(false);
               let data = response.data ? response.data.data : [];
               data = data.map((value) => {
                  value.id = value._id;
                  return value;
               });
               if (fromStart) {
                  setResults(data);
                  setLimit(temp);
               } else {
                  if (results && results.length) {
                     setResults((results) => results.concat(data));
                  } else {
                     setResults(data);
                  }
                  setLimit(temp);
               }
               setLoadMore(response.data.hasMore);
               setTotal(response.data.total_items);
            });
      },
      [limit, results]
   );

   async function getSafetySearch(token) {
      setLoading(true);
      const headers = {
         authorization: "Bearer " + token,
      };
      let limit_to_put = searchLimit;
      await axios
         .post(
            `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SAFETY_API.SEARCH.replace(
               ":limit",
               limit_to_put
            )}`,
            search,
            { headers }
         )
         .then((response) => {
            let temp = searchLimit;
            setLoading(false);
            let data = response.data ? response.data.data : [];
            data = data.map((value) => {
               value.id = value._id;
               return value;
            });
            if (searchLimit === 10) {
               setResults(data);
            } else {
               if (results && results.length) {
                  setResults((results) => results.concat(data));
               } else {
                  setResults(data);
               }
               setSearchLimit((temp += 10));
            }
            setLoadMoreSearch(response.data.hasMore);
            setTotal(response.data.total_items);
         });
   }

   const handleChange = (event) => {
      event.preventDefault();
      const {
         target: { value },
      } = event;
      const {
         target: { name },
      } = event;
      search[name] = value;
      setSearch((prev) => ({
         ...prev,
         [name]: value,
      }));
   };

   function CustomToolbar() {
      return (
         <GridToolbarContainer>
            <GridToolbarExport
               csvOptions={{
                  allColumns: true,
                  fileName: "Safety",
               }}
            />
         </GridToolbarContainer>
      );
   }

   function handleDetailDialogClose() {
      setOpenDetailModal(!openDetailModal);
   }

   useEffect(() => {
      if (props.token && !results) {
         getSafety(props.token, true);
         setLoading(false);
      }
   }, [props.token, results, getSafety]);
   return (
      <React.Fragment>
         <Title>{props.title}</Title>
         {loading && (
            <Box m={5} p={5}>
               <CircularProgress />
            </Box>
         )}
         {!loading && (
            <Box component="span" sx={{ p: 2, borderColor: "#EBF5FB", border: "1px dashed", m: 1 }}>
               <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
                  <Grid item xs={2}>
                     <TextField
                        id="safety-search-site"
                        name="site"
                        label="Site"
                        variant="outlined"
                        value={search.site}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                           if (e.key === "Enter") {
                              e.preventDefault();
                              getSafetySearch(props.token);
                           }
                        }}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <TextField
                        id="safety-search-type"
                        name="type"
                        label="Type"
                        variant="outlined"
                        value={search.issues}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                           if (e.key === "Enter") {
                              e.preventDefault();
                              getSafetySearch(props.token);
                           }
                        }}
                     />
                  </Grid>
                  <Grid item xs={2.5}>
                     <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                           label="Range - From"
                           value={search.date_from}
                           onChange={(newValue) => {
                              setSearch((prev) => ({
                                 ...prev,
                                 date_from: newValue ? new Date(newValue.startOf("day")) : "",
                              }));
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                     <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                           label="Range - Until"
                           value={search.date_to}
                           onChange={(newValue) => {
                              setSearch((prev) => ({
                                 ...prev,
                                 date_to: newValue ? new Date(newValue.endOf("day")) : "",
                              }));
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={1.5} mt={2}>
                     <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Search />}
                        onClick={() => {
                           getSafetySearch(props.token);
                        }}
                     >
                        Search
                     </Button>
                  </Grid>
                  <Grid item xs={1} mt={2}>
                     <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                           setSearch({
                              issues: "",
                              site: "",
                              date_to: "",
                              date_from: "",
                           });
                           setSearchLimit(10);
                           setLoadMoreSearch(false);
                           getSafety(props.token, true);
                        }}
                        startIcon={<Clear />}
                     >
                        Clear
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         )}
         {!loading && (
            <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
               <Grid item xs={12} mt={2}>
                  {results ? (
                     <DataGrid
                        rows={results}
                        columns={columns}
                        autoHeight={true}
                        components={{
                           Toolbar: CustomToolbar,
                           Footer: CustomFooterTotalComponent,
                        }}
                        columnVisibilityModel={{
                           // Hide these columns
                           _id: false,
                        }}
                        loading={loading}
                     />
                  ) : (
                     <React.Fragment></React.Fragment>
                  )}
               </Grid>
            </Grid>
         )}
         <Box m={1} p={1}>
            {loadMore && (
               <Button
                  variant="outlined"
                  onClick={() => {
                     getSafety(props.token, false);
                  }}
               >
                  Load More
               </Button>
            )}
         </Box>
         <Box m={1} p={1}>
            {loadMoreSearch && (
               <Button
                  variant="outlined"
                  onClick={() => {
                     getSafetySearch(props.token);
                  }}
               >
                  Load More
               </Button>
            )}
         </Box>
         {!loading && (
            <Dialog open={openDetailModal} onClose={handleDetailDialogClose} maxWidth="xl">
               <DialogContent>
                  {detailData && detailData.type === CONSTANTS.SAFETY.NEW_SITE && (
                     <SiteSpecificSafety
                        token={props.token}
                        data={detailData}
                        title={`Safety Details`}
                        handleDialogClose={handleDetailDialogClose}
                        getSafety={getSafety}
                     />
                  )}
               </DialogContent>
            </Dialog>
         )}
      </React.Fragment>
   );
}

import axios from "axios";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Title from "../Dashboard/Title";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CONSTANTS from "../../helpers/constants";
import LocalStorage from "../Login/local-storage";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CircularProgress from "@mui/material/CircularProgress";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function AddNotes(props) {
  let ls = new LocalStorage();
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [data, setData] = useState({
    site: "",
    date: new Date(),
    type: "quantifier",
    reason: "install",
    field_persons: [],
    power: ["underground"],
    safety: "YES",
    time_arrival: new Date(new Date(moment().startOf("day")).setHours(9)),
    time_departure: new Date(new Date(moment().startOf("day")).setHours(16)),
    hours: 7,
    scope: "",
    general_notes: "",
    distance_travelled: 0,
    distributor: {
      power: ["solar"],
    },
    issues: [""],
    next_steps: [""],
    stick_up_lengths: [{}],
    tasks: [{}],
    boreholes: [{}],
    user_name: ls.getLocalStorageItemZoho().user_name,
    user_id: ls.getLocalStorageItemZoho().user_id,
    new_categories: [""]
  });

  const handleChange = (event) => {
    event.preventDefault();
    const {
      target: { value },
    } = event;
    const {
      target: { name },
    } = event;
    //data[name] = value
    if (name === "field_persons" || name === "power") {
      setData((prev) => ({
        ...prev,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      if (name === "site") {
        let new_data = siteData.find((item) => item.Name === value);
        setData((prev) => ({
          ...prev,
          site: value,
          distance_travelled: new_data.distance_to_site
            ? new_data.distance_to_site
            : 0,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    uploadToS3().then(() => {
      sendData(data);
    });
  };

  function uploadData(event) {
    event.preventDefault();
    const {
      target: { files },
    } = event;
    const {
      target: { name },
    } = event;
    let new_images = [];
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        new_images.push(files[i]);
      }
      setData((prev) => ({
        ...prev,
        [name]: new_images,
      }));
    }
  }

  function pasteImage(event) {
    if (event.clipboardData && event.clipboardData.files.length) {
      let new_images = [];
      const {
        clipboardData: { files },
      } = event;
      const {
        target: { name },
      } = event;
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type.includes("image")) new_images.push(files[i]);
        }
        setData((prev) => ({
          ...prev,
          [name]: new_images,
        }));
      }
    }
  }

  function getHoursDiff(startDate, endDate) {
    const msInHour = 1000 * 60 * 60;
    return (Math.abs(endDate - startDate) / msInHour).toFixed(2);
  }

  async function uploadToS3() {
    let keys = [
      "general_pictures",
      "site_pictures",
      "power_pictures",
      "gateway_pictures",
      "quant_pictures",
    ];
    let locations = ["General", "Site", "Power", "Gateway", "Quantifier"];
    let date = new Date(data.date);
    let datestring =
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();

    if (data.type === "quantifier/distributor") {
      locations = [
        "General",
        "Site",
        "Power",
        "Gateway",
        "Quantifier",
        "Distributor/Injection",
        "Distributor/Power",
        "Distributor/Water",
      ];
      keys = [
        "general_pictures",
        "site_pictures",
        "power_pictures",
        "gateway_pictures",
        "quant_pictures",
        "distributor_injection",
        "distributor_power",
        "distributor_water",
      ];
    } else if (data.type === "distributor") {
      locations = [
        "General",
        "Site",
        "Distributor/Injection",
        "Distributor/Power",
        "Distributor/Water",
      ];
      keys = [
        "general_pictures",
        "site_pictures",
        "distributor_injection",
        "distributor_power",
        "distributor_water",
      ];
    }
    if (data.new_categories.length && data.new_categories[0] !== "") {
      keys = keys.concat(data.new_categories.filter(item => item))
      locations = locations.concat(data.new_categories.filter(item => item))
      setData((prev) => ({
        ...prev,
        new_categories: data.new_categories.filter(item => item),
      }));
    } else {
      delete data.new_categories
    }
    let resultant_links = [];
    let url = ''
    for (let k = 0; k < keys.length; k++) {
      if (data[keys[k]] && data[keys[k]].length) {
        for (let i = 0; i < data[keys[k]].length; i++) {
          let type = data[keys[k]][i].type;
          type = type.split("/");
          type = type[1];
          let params = {
            // Bucket: process.env.REACT_APP_BUCKET_NOTES,
            Key: `${data.site}/${locations[k]}/${datestring}-${i}.${type}`,
            Body: data[keys[k]][i],
          };
          // setting the specific index image's link
          resultant_links.push(
            `${data.site}/${locations[k]}/${datestring}-${i}.${type}`
          );
          url = await getSignedUrl(params.Key)
          await fetch(url, {
            method: 'PUT',
            body: params.Body,
            headers: {
                'Content-Type': type, // Ensures correct file type is set
            },
          });
        }
        data[keys[k]] = resultant_links;
        resultant_links = [];
      }
    }
  }

  async function getSignedUrl(key) {
    const headers = {
        authorization: "Bearer " + props.token,
    };
    const result = await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_GET_SIGNED_URL}`, { url: key }, { headers })
    return result.data.url
}

  async function sendData(data) {
    const headers = {
      authorization: "Bearer " + props.token,
    };
    await axios
      .post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_ADD_API}`, data, {
        headers,
      })
      .then(() => {
        setLoading(false);
        navigate(CONSTANTS.PAGE_FIELD_NOTES_VIEW);
      });
  }
  async function getSites(token) {
    const headers = {
      authorization: "Bearer " + token,
    };
    await axios
      .get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, {
        headers,
      })
      .then((response) => {
        let data = response.data;
        data.sort((a, b) => {
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
          return 0;
        });
        setSiteData(data);
        setData((prev) => ({
          ...prev,
          site: data[0].Name,
          distance_travelled: data[0].distance_to_site,
        }));
      });
  }

  function toPascalCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    if (props.token && !siteData.length) {
      setLoading(true);
      getSites(props.token);
      setLoading(false);
    }
  }, [props.token, siteData.length]);
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      {loading && (
        <Box m={5} p={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Grid
          container
          rowSpacing={5}
          columnSpacing={3}
          mt={2}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <InputLabel id="site-dropdown-label-notes">Site</InputLabel>
              <Select
                required
                id="site-select-notes"
                name="site"
                labelId="site-dropdown-label-notes"
                value={data.site}
                label="Site"
                onChange={handleChange}
              >
                {siteData &&
                  siteData.map((data) => (
                    <MenuItem value={data.Name} key={data._id}>
                      {data.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <InputLabel id="field-notes-type-dropdown">Type</InputLabel>
              <Select
                required
                id="field-notes-type"
                name="type"
                labelId="field-notes-type-dropdown"
                value={data.type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value={"quantifier"} key={1}>
                  {"Quantifier"}
                </MenuItem>
                <MenuItem value={"distributor"} key={2}>
                  {"Distributor"}
                </MenuItem>
                <MenuItem value={"quantifier/distributor"} key={2}>
                  {"Both"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                required
                label="Date of Visit"
                value={data.date}
                onChange={(newValue) => {
                  setData((prev) => ({
                    ...prev,
                    date: new Date(newValue.startOf("day")),
                    time_arrival: new Date(newValue.startOf("day")).setHours(9),
                    time_departure: new Date(newValue.startOf("day")).setHours(
                      16
                    ),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "50%",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                required
                label="Time of Site Arrival"
                value={data.time_arrival}
                onChange={(newValue) => {
                  setData((prev) => ({
                    ...prev,
                    time_arrival: new Date(newValue),
                    hours: getHoursDiff(
                      new Date(newValue),
                      data.time_departure
                    ),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "50%",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                required
                label="Time of Site Departure"
                value={data.time_departure}
                onChange={(newValue) => {
                  setData((prev) => ({
                    ...prev,
                    time_departure: new Date(newValue),
                    hours: getHoursDiff(data.time_arrival, new Date(newValue)),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "50%",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <InputLabel id="field-notes-reason-dropdown">Reason</InputLabel>
              <Select
                required
                id="field-notes-reason"
                name="reason"
                labelId="field-notes-reason-dropdown"
                value={data.reason}
                label="Reason"
                onChange={handleChange}
              >
                <MenuItem value={"install"} key={1}>
                  {"Install"}
                </MenuItem>
                <MenuItem value={"maintenance"} key={2}>
                  {"Maintenance"}
                </MenuItem>
                <MenuItem value={"debrief"} key={3}>
                  {"Debrief"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <InputLabel id="demo-multiple-name-label">
                Field Personal
              </InputLabel>
              <Select
                multiple
                id="demo-multiple-name"
                labelId="demo-multiple-name-label"
                name="field_persons"
                value={data.field_persons}
                onChange={handleChange}
                input={<OutlinedInput label="Field Personal" />}
              >
                {CONSTANTS.FIELD_PERSONAL.map((name, id) => (
                  <MenuItem key={id} value={name.toLocaleUpperCase()}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="scope"
              name="scope"
              label="Scope of Work"
              variant="outlined"
              multiline
              value={data.scope}
              onChange={handleChange}
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="general_notes"
              name="general_notes"
              label="General Notes"
              variant="outlined"
              multiline
              value={data.general_notes}
              onChange={handleChange}
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              sx={{
                width: "50%",
              }}
            >
              <InputLabel id="field-notes-safety-dropdown">
                Safety Forms Filled
              </InputLabel>
              <Select
                required
                id="field-notes-safety-select"
                name="safety"
                labelId="field-notes-safety-dropdown"
                value={data.safety}
                label="Safety Forms Filled"
                onChange={handleChange}
              >
                <MenuItem value={"YES"} key={1}>
                  {"Yes"}
                </MenuItem>
                <MenuItem value={"NO"} key={2}>
                  {"No"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {data.safety === "NO" && (
            <Grid item xs={4}>
              <TextField
                id="reason"
                name="reason_safety"
                label="Reason No Safety Form"
                variant="outlined"
                multiline
                value={data.reason_safety}
                onChange={handleChange}
                sx={{
                  width: "50%",
                }}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Stack spacing={2}>
              <TextField
                multiline
                placeholder={
                  data.site_pictures && data.site_pictures.length
                    ? ` Selected: ${data.site_pictures.length
                    }\n ${data.site_pictures.map((value) => value.name)}`
                    : ""
                }
                label={"Selected Site Picture(s)"}
                style={{
                  width: "50%",
                  alignSelf: "center",
                }}
                color="secondary"
                variant="filled"
                focused
                name="site_pictures"
                onPaste={pasteImage}
              />
              <Button
                variant="outlined"
                component="label"
                startIcon={<PhotoCameraIcon />}
                style={{
                  width: "50%",
                  alignSelf: "center",
                }}
              >
                Select Site Pic(s)
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  name="site_pictures"
                  onChange={uploadData}
                  hidden
                />
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={2}>
              <TextField
                multiline
                placeholder={
                  data.general_pictures && data.general_pictures.length
                    ? ` Selected: ${data.general_pictures.length
                    }\n ${data.general_pictures.map((value) => value.name)}`
                    : ""
                }
                label={"Selected General Picture(s)"}
                // inputProps={
                //     { readOnly: true }
                // }
                style={{
                  width: "50%",
                  alignSelf: "center",
                }}
                color="secondary"
                variant="filled"
                focused
                name="general_pictures"
                onPaste={pasteImage}
              />
              <Button
                variant="outlined"
                component="label"
                startIcon={<PhotoCameraIcon />}
                style={{
                  width: "50%",
                  alignSelf: "center",
                }}
              >
                Select General Pic(s)
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  name="general_pictures"
                  onChange={uploadData}
                  hidden
                />
              </Button>
            </Stack>
          </Grid>
          {(data.type === "quantifier" ||
            data.type === "quantifier/distributor") && (
              <React.Fragment>
                {data.reason === "install" && (
                  <React.Fragment>
                    <Grid item xs={4}>
                      <FormControl
                        sx={{
                          width: "50%",
                        }}
                      >
                        <InputLabel id="field-notes-power-dropdown">
                          Power (Quantifier)
                        </InputLabel>
                        <Select
                          multiple
                          id="field-notes-power"
                          name="power"
                          labelId="field-notes-power-dropdown"
                          value={data.power}
                          onChange={handleChange}
                          input={<OutlinedInput label="Power (Quantifier)" />}
                        >
                          <MenuItem value={"underground"} key={1}>
                            {"Underground"}
                          </MenuItem>
                          <MenuItem value={"solar"} key={2}>
                            {"Solar"}
                          </MenuItem>
                          <MenuItem value={"hardwired"} key={3}>
                            {"Hardwired"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="power_notes"
                        name="power_notes"
                        label="Power Notes (Quantifier)"
                        variant="outlined"
                        value={data.power_notes}
                        multiline
                        onChange={handleChange}
                        sx={{
                          width: "50%",
                        }}
                      />
                    </Grid>
                    {data.stick_up_lengths.map((value, idx) => (
                      <Grid key={idx + 1} item xs={4}>
                        <Stack spacing={2}>
                          <TextField
                            multiline
                            id={`${idx}-quantifier`}
                            label={"Quantifier"}
                            style={{
                              width: "50%",
                              alignSelf: "center",
                            }}
                            value={value.quantifier}
                            onChange={(e) => {
                              let stick_up_lengths = [...data.stick_up_lengths];
                              stick_up_lengths[idx].quantifier = e.target.value;
                              setData((prev) => ({
                                ...prev,
                                stick_up_lengths,
                              }));
                            }}
                          />
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                              alignSelf: "center",
                            }}
                          >
                            <TextField
                              id={`${idx}-length`}
                              label="Stick Up Length"
                              variant="outlined"
                              type={"number"}
                              sx={{
                                width: "85%",
                              }}
                              value={value.stick_up_length}
                              onChange={(e) => {
                                let stick_up_lengths = [...data.stick_up_lengths];
                                stick_up_lengths[idx].stick_up_length =
                                  parseFloat(e.target.value);
                                setData((prev) => ({
                                  ...prev,
                                  stick_up_lengths,
                                }));
                              }}
                            />
                            <AddCircleIcon
                              color="primary"
                              sx={{
                                width: "15%",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let stick_up_lengths = [...data.stick_up_lengths];
                                stick_up_lengths.push({});
                                setData((prev) => ({
                                  ...prev,
                                  stick_up_lengths,
                                }));
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                    {data.power && data.power.includes("underground") && (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <TextField
                            id="power_flushmount"
                            name="power_flushmount"
                            label="Flushmount Size"
                            variant="outlined"
                            value={data.power_flushmount}
                            onChange={handleChange}
                            sx={{
                              width: "50%",
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    {data.power && data.power.includes("solar") && (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <TextField
                            id="solarpanel_distance"
                            name="solarpanel_distance"
                            label="Distance to Solar Panel"
                            variant="outlined"
                            value={data.power_flushmount}
                            onChange={handleChange}
                            sx={{
                              width: "50%",
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    {data.boreholes.map((value, idx) => (
                      <Grid key={idx + 1} item xs={4}>
                        <Stack spacing={2}>
                          <TextField
                            id={`${idx}-borehole`}
                            label={"Borehole/Monitoring Well"}
                            style={{
                              width: "50%",
                              alignSelf: "center",
                            }}
                            value={value.borehole}
                            onChange={(e) => {
                              let boreholes = [...data.boreholes];
                              boreholes[idx].borehole = e.target.value;
                              setData((prev) => ({
                                ...prev,
                                boreholes,
                              }));
                            }}
                          />

                          <TextField
                            id={`${idx}-depth`}
                            label="Depth"
                            variant="outlined"
                            type={"number"}
                            onChange={(e) => {
                              let boreholes = [...data.boreholes];
                              boreholes[idx].depth = parseFloat(e.target.value);
                              setData((prev) => ({
                                ...prev,
                                boreholes,
                              }));
                            }}
                            value={value.depth}
                            sx={{
                              width: "50%",
                              alignSelf: "center",
                            }}
                          />
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                              alignSelf: "center",
                            }}
                          >
                            <TextField
                              id={`${idx}-depth_to_water`}
                              label="Depth to Water"
                              variant="outlined"
                              type={"number"}
                              onChange={(e) => {
                                let boreholes = [...data.boreholes];
                                boreholes[idx].depth_to_water = parseFloat(
                                  e.target.value
                                );
                                setData((prev) => ({
                                  ...prev,
                                  boreholes,
                                }));
                              }}
                              value={value.depth_to_water}
                              sx={{
                                width: "80%",
                              }}
                            />
                            <AddCircleIcon
                              color="primary"
                              sx={{
                                width: "15%",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let boreholes = [...data.boreholes];
                                boreholes.push({});
                                setData((prev) => ({
                                  ...prev,
                                  boreholes,
                                }));
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                    <Grid item xs={4}>
                      <Stack spacing={2}>
                        <TextField
                          multiline
                          aria-label="empty textarea"
                          placeholder={
                            data.power_pictures && data.power_pictures.length
                              ? ` Selected: ${data.power_pictures.length
                              }\n ${data.power_pictures.map(
                                (value) => value.name
                              )}`
                              : ""
                          }
                          label={"Selected Power Picture(s)"}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                          color="secondary"
                          variant="filled"
                          focused
                          name="power_pictures"
                          onPaste={pasteImage}
                        />
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                        >
                          Select Power Pic(s)
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="power_pictures"
                            onChange={uploadData}
                            hidden
                          />
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="gateway_location"
                        name="gateway_location"
                        label="Gateway Location"
                        variant="outlined"
                        value={data.gateway_location}
                        multiline
                        onChange={handleChange}
                        sx={{
                          width: "50%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={2}>
                        <TextField
                          multiline
                          aria-label="empty textarea"
                          placeholder={
                            data.gateway_pictures && data.gateway_pictures.length
                              ? ` Selected: ${data.gateway_pictures.length
                              }\n ${data.gateway_pictures.map(
                                (value) => value.name
                              )}`
                              : ""
                          }
                          label={"Selected Gateway Location Picture(s)"}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                          color="secondary"
                          variant="filled"
                          focused
                          name="gateway_pictures"
                          onPaste={pasteImage}
                        />
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                        >
                          Select Gateway Pic(s)
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="gateway_pictures"
                            onChange={uploadData}
                            hidden
                          />
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={2}>
                        <TextField
                          multiline
                          aria-label="empty textarea"
                          placeholder={
                            data.quant_pictures && data.quant_pictures.length
                              ? ` Selected: ${data.quant_pictures.length
                              }\n ${data.quant_pictures.map(
                                (value) => value.name
                              )}`
                              : ""
                          }
                          label={"Selected Quantifier Picture(s)"}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                          color="secondary"
                          variant="filled"
                          focused
                          name="quant_pictures"
                          onPaste={pasteImage}
                        />
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                        >
                          Select Quantifier Pic(s)
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="quant_pictures"
                            onChange={uploadData}
                            hidden
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          {(data.type === "distributor" ||
            data.type === "quantifier/distributor") && (
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="distributor_location"
                    name="distributor_location"
                    label="Distributor Location"
                    variant="outlined"
                    multiline
                    placeholder="Describe Location"
                    value={data.distributor.location}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          location: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="water_connection"
                    name="water_connection"
                    label="Water Connection"
                    variant="outlined"
                    placeholder="Tap, Well, Notes !"
                    multiline
                    value={data.distributor.water_connection}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          water_connection: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <TextField
                      multiline
                      placeholder={
                        data.distributor_water && data.distributor_water.length
                          ? ` Selected: ${data.distributor_water.length
                          }\n ${data.distributor_water.map(
                            (value) => value.name
                          )}`
                          : ""
                      }
                      label={"Selected Distributor Water Picture(s)"}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                      color="secondary"
                      variant="filled"
                      focused
                      name="distributor_water"
                      onPaste={pasteImage}
                    />
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<PhotoCameraIcon />}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                    >
                      Select Distributor Water Pic(s)
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        name="distributor_water"
                        onChange={uploadData}
                        hidden
                      />
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="injection_point"
                    name="injection_point"
                    label="Injection Point"
                    variant="outlined"
                    multiline
                    placeholder="Notes"
                    value={data.distributor.injection_point}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          injection_point: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <TextField
                      multiline
                      placeholder={
                        data.distributor_injection &&
                          data.distributor_injection.length
                          ? ` Selected: ${data.distributor_injection.length
                          }\n ${data.distributor_injection.map(
                            (value) => value.name
                          )}`
                          : ""
                      }
                      label={"Selected Distributor Injection Picture(s)"}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                      color="secondary"
                      variant="filled"
                      focused
                      name="distributor_injection"
                      onPaste={pasteImage}
                    />
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<PhotoCameraIcon />}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                    >
                      Select Distributor Injection Pic(s)
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        name="distributor_injection"
                        onChange={uploadData}
                        hidden
                      />
                    </Button>
                  </Stack>
                </Grid>
                {data.reason === "install" && (
                  <React.Fragment>
                    <Grid item xs={4}>
                      <FormControl
                        sx={{
                          width: "50%",
                        }}
                      >
                        <InputLabel id="field-notes-distributor-power-dropdown">
                          Power (Distributor)
                        </InputLabel>
                        <Select
                          multiple
                          id="field-notes-distributor-power"
                          name="power"
                          labelId="field-notes-distributor-power-dropdown"
                          value={data.distributor.power}
                          onChange={(e) => {
                            let { value } = e.target;
                            setData((prev) => ({
                              ...prev,
                              distributor: {
                                ...prev.distributor,
                                power:
                                  typeof value === "string"
                                    ? value.split(",")
                                    : value,
                              },
                            }));
                          }}
                          input={<OutlinedInput label="Power (Distributor)" />}
                        >
                          <MenuItem value={"underground"} key={1}>
                            {"Underground"}
                          </MenuItem>
                          <MenuItem value={"solar"} key={2}>
                            {"Solar"}
                          </MenuItem>
                          <MenuItem value={"hardwired"} key={3}>
                            {"Hardwired"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="power_notes"
                        name="power_notes"
                        label="Power Notes (Distributor)"
                        variant="outlined"
                        value={data.distributor.power_notes}
                        multiline
                        onChange={(e) => {
                          let { value } = e.target;
                          setData((prev) => ({
                            ...prev,
                            distributor: {
                              ...prev.distributor,
                              power_notes: value,
                            },
                          }));
                        }}
                        sx={{
                          width: "50%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={2}>
                        <TextField
                          multiline
                          placeholder={
                            data.distributor_power &&
                              data.distributor_power.length
                              ? ` Selected: ${data.distributor_power.length
                              }\n ${data.distributor_power.map(
                                (value) => value.name
                              )}`
                              : ""
                          }
                          label={"Selected Distributor Power Picture(s)"}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                          color="secondary"
                          variant="filled"
                          focused
                          name="distributor_power"
                          onPaste={pasteImage}
                        />
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          style={{
                            width: "50%",
                            alignSelf: "center",
                          }}
                        >
                          Select Distributor Power Pic(s)
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="distributor_power"
                            onChange={uploadData}
                            hidden
                          />
                        </Button>
                      </Stack>
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={4}>
                  <TextField
                    id="carbon_bags"
                    name="carbon_bags"
                    label="Carbon Bags"
                    variant="outlined"
                    multiline
                    placeholder="Notes"
                    value={data.distributor.carbon_bags}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          carbon_bags: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="equipment_used"
                    name="equipment_used"
                    label="Equipment Used"
                    variant="outlined"
                    multiline
                    placeholder="Notes"
                    value={data.distributor.equipment_used}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          equipment_used: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="settings"
                    name="settings"
                    label="Distributor Settings"
                    variant="outlined"
                    multiline
                    placeholder="Notes"
                    value={data.distributor.settings}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          settings: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="triply_tank"
                    name="triply_tank"
                    label="Triply Tank"
                    variant="outlined"
                    multiline
                    placeholder="Measurement"
                    value={data.distributor.triply_tank}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          triply_tank: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="iron_citrate"
                    name="iron_citrate"
                    label="Iron Citrate"
                    variant="outlined"
                    multiline
                    placeholder="Measurement"
                    value={data.distributor.iron_citrate}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          iron_citrate: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="injection_settings"
                    name="injection_settings"
                    label="Injection Settings"
                    variant="outlined"
                    multiline
                    placeholder="Timer for 6 hours on off?"
                    value={data.distributor.injection_settings}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        distributor: {
                          ...prev.distributor,
                          injection_settings: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      width: "50%",
                    }}
                  />
                </Grid>
              </React.Fragment>
            )}
          {data.tasks.map((value, idx) => (
            <Grid key={idx + 1} item xs={4}>
              <Stack spacing={2}>
                <TextField
                  multiline
                  id={`${idx}-Task Completed`}
                  label={"Task Completed"}
                  style={{
                    width: "50%",
                    alignSelf: "center",
                  }}
                  value={value.task}
                  onChange={(e) => {
                    let tasks = [...data.tasks];
                    tasks[idx].task = e.target.value;
                    setData((prev) => ({
                      ...prev,
                      tasks,
                    }));
                  }}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  <TextField
                    id={`${idx}-Time Spent`}
                    label="Time Spent"
                    variant="outlined"
                    type={"number"}
                    sx={{
                      width: "85%",
                    }}
                    value={value.time_spent}
                    onChange={(e) => {
                      let tasks = [...data.tasks];
                      tasks[idx].time_spent = parseFloat(e.target.value);
                      setData((prev) => ({
                        ...prev,
                        tasks,
                      }));
                    }}
                  />
                  <AddCircleIcon
                    color="primary"
                    sx={{
                      width: "15%",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let tasks = [...data.tasks];
                      tasks.push({});
                      setData((prev) => ({
                        ...prev,
                        tasks,
                      }));
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          ))}

          <Grid item xs={4}>
            <TextField
              id="special_tools"
              name="special_tools"
              label="Special Tools Needed"
              variant="outlined"
              value={data.special_tools}
              multiline
              onChange={handleChange}
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          {data.issues.map((value, idx) => (
            <Grid key={idx} item xs={4}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  multiline
                  id={`${idx}-issue`}
                  label={"Issues"}
                  value={value}
                  onChange={(e) => {
                    let issues = [...data.issues];
                    issues[idx] = e.target.value;
                    setData((prev) => ({
                      ...prev,
                      issues,
                    }));
                  }}
                />
                <AddCircleIcon
                  color="primary"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    let issues = [...data.issues];
                    issues.push("");
                    setData((prev) => ({
                      ...prev,
                      issues,
                    }));
                  }}
                />
              </Stack>
            </Grid>
          ))}
          {data.next_steps.map((value, idx) => (
            <Grid key={idx} item xs={4}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  multiline
                  id={`${idx}-next_steps`}
                  label={"Next Steps"}
                  value={value}
                  onChange={(e) => {
                    let next_steps = [...data.next_steps];
                    next_steps[idx] = e.target.value;
                    setData((prev) => ({
                      ...prev,
                      next_steps,
                    }));
                  }}
                />
                <AddCircleIcon
                  color="primary"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let next_steps = [...data.next_steps];
                    next_steps.push("");
                    setData((prev) => ({
                      ...prev,
                      next_steps,
                    }));
                  }}
                />
              </Stack>
            </Grid>
          ))}
          <Grid item xs={4}>
            <TextField
              id="hours"
              name="hours"
              label="Hours on Site"
              variant="outlined"
              value={data.hours}
              disabled={true}
              InputProps={{
                rows: 1,
              }}
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="distance_travelled"
              name="distance_travelled"
              label="Distance to Site (KM)"
              variant="outlined"
              type={"number"}
              value={data.distance_travelled}
              onChange={handleChange}
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          {data.new_categories.map((value, idx) => (
            <Grid key={idx} item xs={4}>
              <Stack
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {
                  data[value] ?
                    <TextField
                      multiline
                      placeholder={
                        data[value] && data[value].length
                          ? ` Selected: ${data[value].length
                          }\n ${data[value].map((value) => value.name)}`
                          : ""
                      }
                      label={`Selected ${value} Picture(s)`}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                      color="secondary"
                      variant="filled"
                      focused
                      name={value}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    : <TextField
                      multiline
                      id={`${idx}-new_categories`}
                      label={"Add Image Category"}
                      value={value}
                      style={{
                        width: "50%",
                        alignSelf: "center",
                      }}
                      onChange={(e) => {
                        let new_categories = [...data.new_categories];
                        if (e.target.value) {
                          new_categories[idx] = toPascalCase(e.target.value)
                          setData((prev) => ({
                            ...prev,
                            new_categories,
                          }));
                        } else {
                          new_categories[idx] = e.target.value
                          setData((prev) => ({
                            ...prev,
                            new_categories,
                          }));
                        }
                      }}
                    />
                }
                {
                  value ?
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        component="label"
                        startIcon={<PhotoCameraIcon />}
                        style={{
                          width: "100%",
                          alignSelf: "center",
                        }}
                      >
                        {`Select ${toPascalCase(value)} Pic(s)`}
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          name={toPascalCase(value).replaceAll(" ", "_")}
                          onChange={uploadData}
                          hidden
                        />
                      </Button>
                      {
                        idx === data.new_categories.length - 1 ?
                          <AddCircleIcon
                            color="primary"
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let new_categories = [...data.new_categories];
                              new_categories.push("");
                              setData((prev) => ({
                                ...prev,
                                new_categories,
                              }));
                            }}
                          /> : <></>
                      }

                    </Stack> : <></>
                }
              </Stack>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              sx={{ justifyContent: "center" }}
              spacing={1}
            >
              <Typography variant="h6">Current User</Typography>
              <Typography variant="h6" color={"red"}>
                {data.user_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              size="large"
              startIcon={<CloudUploadIcon />}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import AirIcon from '@mui/icons-material/Air';
import { useNavigate } from "react-router-dom";
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import LocalStorage from "../Login/local-storage";
import Typography from '@mui/material/Typography';
import CloudIcon from '@mui/icons-material/Cloud';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SevereColdIcon from '@mui/icons-material/SevereCold';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function ChecklistAdd(props) {
    const navigate = useNavigate();
    let ls = new LocalStorage()
    const [data, setData] = useState({
        sites: [],
        trip_start: new Date(),
        trip_end: new Date(),
        quantifier_installing: false,
        borehole_drilling: false,
        extra_quantifier: false,
        installing_distributor: false,
        distributor_consumables_taking: false,
        working_tracer: false,
        power_infrastructure: false,
        quant_install: {},
        borehole: {
            outer_casing_cap: ''
        },
        general: {},
        extra_quant: {},
        distributor: {},
        safety: {},
        tracer: {},
        power: {},
        distributor_consumables: {},
        weather_data: [],
        user_name: ls.getLocalStorageItemZoho().user_name,
        user_id: ls.getLocalStorageItemZoho().user_id
    })
    const [loading, setLoading] = useState(false)
    const [siteData, setSiteData] = useState([])
    const [disableField, setDisableField] = useState(false)
    // const [weatherData, setWeatherData] = useState([])
    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        const { target: { id } } = event;
        const { target: { checked } } = event;
        if (id === 'safety') {
            setData(prev => ({
                ...prev,
                safety: {
                    ...prev.safety,
                    [name]: checked
                }
            }))
        } else if (id === 'general') {
            setData(prev => ({
                ...prev,
                general: {
                    ...prev.general,
                    [name]: checked
                }
            }))
        } else {
            setData(prev => ({
                ...prev,
                [name]: value
            }))
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        data.site_search = data.sites ? data.sites.toLocaleString().toLocaleUpperCase() : ''
        sendChecklist(props.token, data)
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        setLoading(true)
        data.site_search = data.sites ? data.sites.toLocaleString().toLocaleUpperCase() : ''
        updateChecklist(props.token, data)
    }

    const getWeatherDataClick = (event => {
        event.preventDefault()
        let days = data.trip_end.getTime() - data.trip_start.getTime()
        days = days / (1000 * 3600 * 24)
        days = Math.ceil(days)
        days += 1
        days = days * 6
        if (days > 40) {
            days = 40
        }
        setLoading(true)
        getWeatherData(days)
        setLoading(false)
    })

    async function getWeatherData(days) {
        let sites = data.sites
        let current_site_count = 0
        let merged_data = []
        let current_site = ''
        let current_site_data = {}
        let response = []
        while (current_site_count < sites.length) {
            current_site = sites[current_site_count]
            // eslint-disable-next-line
            current_site_data = siteData.find(item => item.Name === current_site)
            if (current_site_data.lat && current_site_data.lng) {
                response = await axios.get(`${CONSTANTS.OPEN_WEATHER_API}&lat=${current_site_data.lat}&lon=${current_site_data.lng}&appid=${process.env.REACT_APP_WEATHER_TOKEN}&cnt=${days}`)
            } else {
                response = await axios.get(`${CONSTANTS.OPEN_WEATHER_API}&q=${current_site},CA&appid=${process.env.REACT_APP_WEATHER_TOKEN}&cnt=${days}`)
            }
            let original_date = new Date()
            let date = 0
            let obj_keys = {}
            let current_data = {}
            let sun_rise = new Date(response.data.city.sunrise * 1000)
            let sun_set = new Date(response.data.city.sunset * 1000)
            let hour = 0
            response.data.list.forEach(item => {
                original_date = new Date(item.dt * 1000)
                hour = original_date.getHours()
                date = original_date.getDate()
                current_data = {
                    forcasted_datetime: original_date,
                    temperature_feels_like: item.main.feels_like,
                    temperature: item.main.temp,
                    humidity: item.main.humidity,
                    pressure: item.main.pressure,
                    visibility: item.visibility,
                    wind_speed: item.wind.speed,
                    wind_gust: item.wind.gust,
                    cloud_percentage: item.clouds.all,
                    weather_type: item.weather[0].main,
                    weather_desc: item.weather[0].description,
                }
                if (date in obj_keys) {
                    if (hour > 7 && hour < 19)
                        obj_keys[date].push(current_data)

                } else {
                    if (hour > 7 && hour < 19)
                        obj_keys[date] = [current_data]
                }
            });
            let complete_data = []
            for (const key in obj_keys) {
                current_data = obj_keys[key]
                const current_data_length = current_data.length;
                complete_data.push({
                    forcasted_datetime: current_data[0].forcasted_datetime,
                    site: current_site_data.fullName,
                    temperature_feels_like: current_data.reduce((total, next) => total + next.temperature_feels_like, 0) / current_data_length,
                    temperature: current_data.reduce((total, next) => total + next.temperature, 0) / current_data_length,
                    humidity: current_data.reduce((total, next) => total + next.humidity, 0) / current_data_length,
                    pressure: current_data.reduce((total, next) => total + next.pressure, 0) / current_data_length,
                    visibility: current_data.reduce((total, next) => total + next.visibility, 0) / current_data_length,
                    wind_speed: current_data.reduce((total, next) => total + next.wind_speed, 0) / current_data_length,
                    wind_gust: current_data.reduce((total, next) => total + next.wind_gust, 0) / current_data_length,
                    cloud_percentage: current_data.reduce((total, next) => total + next.cloud_percentage, 0) / current_data_length,
                    weather_type: current_data[0].weather_type,
                    weather_desc: current_data[0].weather_desc,
                    sunrise: sun_rise,
                    sunset: sun_set
                })
            }
            merged_data = merged_data.concat(complete_data)
            current_site_count += 1
        }
        setData(prev => ({
            ...prev,
            weather_data: merged_data
        }))
    }
    async function sendChecklist(token, payload) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.CHECKLIST_ADD_API_URL}`, payload, { headers })
            .then(() => {
                setLoading(false)
                navigate(CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_VIEW)
            })
    }
    async function updateChecklist(token, payload) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.CHECKLIST_UPDATE_API_URL}`, payload, { headers })
            .then(() => {
                setLoading(false)
                props.handleDetailDialogClose()
                props.getChecklist(token, true)
            })
    }
    async function getSites(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                data.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0
                })
                setSiteData(data)
            })
    }
    useEffect(() => {
        if (props.isDetail) {
            let temp = props.data
            delete temp._id
            delete temp.added_date
            setData(temp)
            setSiteData(props.siteData)
            setDisableField(true)
        } else {
            if (props.token && !siteData.length) {
                setLoading(true)
                getSites(props.token)
                setLoading(false)
            }
        }
    }, [props.token, siteData.length, props.data, props.isDetail, props.siteData])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <React.Fragment>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={2}>
                                <Typography variant="h6">
                                    Trip Information
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{
                                width: "85%"
                            }}>
                                <InputLabel id="site-dropdown-label-checklist">Site(s)</InputLabel>
                                <Select
                                    disabled={disableField}
                                    multiple
                                    id="site-select-checklist"
                                    name="sites"
                                    labelId="site-dropdown-label-checklist"
                                    value={data.sites}
                                    label="Site(s)"
                                    placeholder='Pick single or multiple sites'
                                    input={<OutlinedInput label="Name" />}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            sites: typeof value === 'string' ? value.split(',') : value
                                        }))
                                    }}
                                >
                                    {siteData && siteData.map(data =>
                                        <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disabled={disableField}
                                    required
                                    label="Trip - Start"
                                    placeholder='Trip start date'
                                    value={data.trip_start}
                                    onChange={(newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            trip_start: new Date(newValue.startOf('day')),
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disabled={disableField}
                                    required
                                    label="Trip - End"
                                    placeholder='Trip end date'
                                    value={data.trip_end}
                                    onChange={(newValue) => {
                                        setData(prev => ({
                                            ...prev,
                                            trip_end: new Date(newValue.endOf('day')),
                                        }))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {
                            (!disableField && !loading) &&
                            <Grid item xs={12}>
                                <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={2}>
                                    <Button
                                        variant="contained"
                                        onClick={getWeatherDataClick}
                                        size="large"
                                        startIcon={<ThermostatIcon />}
                                        disabled={!data.trip_start || !data.trip_end || !data.sites.length}
                                    >
                                        Get Weather Data
                                    </Button>
                                </Stack>
                            </Grid>}
                        {
                            (data.weather_data && data.weather_data.length) ?
                                data.weather_data.map((weather, key) => (
                                    <Grid item xs={4} key={key}>
                                        <Box sx={{ border: 1, borderColor: 'grey.500', borderRadius: 1 }}>
                                            <Stack direction={'column'} spacing={2}>
                                                <b>{weather.site}</b>
                                                <b>{new Date(weather.forcasted_datetime).toDateString('YYYY-MM-DD')}</b>
                                            </Stack>
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <ThermostatIcon sx={{ color: 'green' }} />
                                                <p>Temperature <b>{weather.temperature.toFixed(2)}</b> °C</p>
                                            </Stack>
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <ThermostatIcon sx={{ color: 'red' }} />
                                                <p>Feels Like <b>{weather.temperature_feels_like.toFixed(2)}</b> °C</p>
                                            </Stack>
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <VisibilityIcon sx={{ color: 'blue' }} />
                                                <p>Visibility <b>{weather.visibility.toFixed(2)}</b> m</p>
                                            </Stack>
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <AirIcon sx={{ color: 'grey' }} />
                                                <p>Wind Speed <b>{weather.wind_speed.toFixed(2)}</b> km/hr</p>
                                            </Stack>
                                            {
                                                weather.weather_type === 'Clouds' &&
                                                <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                    <CloudIcon />
                                                    <p>{weather.weather_desc}</p>
                                                </Stack>
                                            }
                                            {
                                                weather.weather_type === 'Rain' &&
                                                <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                    <ThunderstormIcon />
                                                    <p>{weather.weather_desc}</p>
                                                </Stack>
                                            }
                                            {
                                                weather.weather_type === 'Snow' &&
                                                <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                    <SevereColdIcon />
                                                    <p>{weather.weather_desc}</p>
                                                </Stack>
                                            }
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <WbTwilightIcon sx={{ color: 'orange' }} />
                                                <p>Sun Rise {new Date(weather.sunrise).toTimeString()} </p>
                                            </Stack>
                                            <Stack direction={'row'} sx={{ ml: '2%' }} spacing={2}>
                                                <NightsStayIcon sx={{ color: 'grey' }} />
                                                <p>Sun Set {new Date(weather.sunset).toTimeString()} </p>
                                            </Stack>
                                        </Box >
                                    </Grid>
                                )) : <React.Fragment />
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Quantifier Install
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: '85%' }}>
                                <InputLabel id="quantifier_installing">Quantifier Installing</InputLabel>
                                <Select
                                    disabled={disableField}
                                    required
                                    id="quantifier_installing"
                                    name="quantifier_installing"
                                    labelId="quantifier_installing"
                                    value={data.quantifier_installing}
                                    label="Borehole Drilling"
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            quantifier_installing: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.quantifier_installing &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="quantifiers" name='quantifiers' label="Quantifiers" variant="outlined" placeholder='Number'
                                        multiline
                                        value={data.quant_install.quantifiers ? data.quant_install.quantifiers : ''}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    quantifiers: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="gateway" name='gateway' label="Gateway" variant="outlined" placeholder='Number'
                                        multiline
                                        value={data.quant_install.gateway}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    gateway: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="tcm" name='tcm' label="TCM" variant="outlined" placeholder='Number'
                                        value={data.quant_install.tcm}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    tcm: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="tcm_extra" name='tcm_extra' label="Extra TCM(s)" variant="outlined" placeholder='Record Numbers'
                                        multiline
                                        value={data.quant_install.tcm_extra}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    tcm_extra: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="sensor_housing_extra" name='sensor_housing_extra' label="Extra Sensor Housing" variant="outlined" placeholder='Record Numbers'
                                        multiline
                                        value={data.quant_install.sensor_housing_extra}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    sensor_packs_extra: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='quant_install'
                                                name="torq_screwdriver"
                                                checked={data.quant_install.torq_screwdriver ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        quant_install: {
                                                            ...prev.quant_install,
                                                            torq_screwdriver: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Quantifier Torq Screwdriver" />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="special_requirements" name='special_requirements' label="Special Requirements" variant="outlined" placeholder='Notes'
                                        value={data.quant_install.special_requirements}
                                        disabled={disableField}
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                quant_install: {
                                                    ...prev.quant_install,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>}
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Power Infrastructure
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: "85%" }}>
                                <InputLabel id="porwer_infrastructure">Installing/Changing Power</InputLabel>
                                <Select
                                    disabled={disableField}
                                    required
                                    id="power_infrastructure"
                                    name="power_infrastructure"
                                    labelId="porwer_infrastructure"
                                    value={data.power_infrastructure}
                                    label="Installing/Changing Power"
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            power_infrastructure: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={false} key={1}>{'No'}</MenuItem>
                                    <MenuItem value={'SOLAR'} key={2}>{'Solar'}</MenuItem>
                                    <MenuItem value={'BATTERY'} key={3}>{'Battery'}</MenuItem>
                                    <MenuItem value={'BOTH'} key={4}>{'Both'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            (data.power_infrastructure && (data.power_infrastructure === 'BOTH' || data.power_infrastructure === 'SOLAR')) &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="solar_posts" name='solar_posts' label="Solar Posts" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.power.solar_posts}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    solar_posts: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="solar_panel" name='solar_panel' label="Solar Panels & Extras" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.power.solar_panel}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    solar_panel: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="solar_panel_brackets" name='solar_panel_brackets' label="Solar Panel Brackets" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.power.solar_panel_brackets}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    solar_panel_brackets: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="solar_battery_box_and_battery" name='solar_battery_box_and_battery' label="Solar Battery Box & Battery" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.power.solar_battery_box_and_battery}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    solar_battery_box_and_battery: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="bolts" name='bolts' label="Bolts" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.power.bolts}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    bolts: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            (data.power_infrastructure && (data.power_infrastructure === 'BOTH' || data.power_infrastructure === 'BATTERY')) &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="battery_pack_and_tcm" name='battery_pack_and_tcm' label="Battery & TCM Pack" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.power.battery_pack_and_tcm}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    battery_pack_and_tcm: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="flushmount" name='flushmount' label="Flushmount" variant="outlined"
                                        multiline
                                        value={data.power.flushmount}
                                        disabled={disableField}
                                        placeholder='Quantity/Type'
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                power: {
                                                    ...prev.power,
                                                    flushmount: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='power'
                                                name="flushmount_wrench_set"
                                                checked={data.power.flushmount_wrench_set ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        power: {
                                                            ...prev.power,
                                                            flushmount_wrench_set: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Flushmount Socket Wrench Set" />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Quantifier Consumables
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: "85%" }}>
                                <InputLabel id="extra_quantifier_label">Quantifier Consumable(s)</InputLabel>
                                <Select
                                    disabled={disableField}
                                    required
                                    id="extra_quantifier"
                                    name="extra_quantifier"
                                    labelId="extra_quantifier_label"
                                    value={data.extra_quantifier}
                                    label="Outter Casing Cap"
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            extra_quantifier: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.extra_quantifier &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="check_all"
                                                checked={data.extra_quant.check_all ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    let { checked } = e.target
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            check_all: checked,
                                                            ten_fuses: checked,
                                                            ten_fuse_holders: checked,
                                                            five_tcm_wires: checked,
                                                            twenty_wire_nuts: checked,
                                                            twenty_wire_butt_connectors: checked,
                                                            ten_ethernet_ends_rj45: checked,
                                                            five_cat5e_cable: checked,
                                                            ten_solar_connectors: checked,
                                                            five_extra_cable_glands: checked,
                                                            ten_rj45_female_to_female_connectors: checked,
                                                            ten_rj45_clips: checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Check All" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_fuses"
                                                checked={data.extra_quant.ten_fuses ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_fuses: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 Fuses" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_fuse_holders"
                                                checked={data.extra_quant.ten_fuse_holders ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_fuse_holders: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 Fuse Holders" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="five_tcm_wires"
                                                checked={data.extra_quant.five_tcm_wires ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            five_tcm_wires: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="5 TCM Wires" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="twenty_wire_nuts"
                                                checked={data.extra_quant.twenty_wire_nuts ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            twenty_wire_nuts: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="20 Wire Nuts" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="twenty_wire_butt_connectors"
                                                checked={data.extra_quant.twenty_wire_butt_connectors ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            twenty_wire_butt_connectors: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="20 Wire Butt Connectors" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_ethernet_ends_rj45"
                                                checked={data.extra_quant.ten_ethernet_ends_rj45 ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_ethernet_ends_rj45: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 Ethernet ends RJ45" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="five_cat5e_cable"
                                                checked={data.extra_quant.five_cat5e_cable ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            five_cat5e_cable: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="5 Cat5e Cable (5+ m in length)" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_solar_connectors"
                                                checked={data.extra_quant.ten_solar_connectors ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_solar_connectors: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 Solar Connectors" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="five_extra_cable_glands"
                                                checked={data.extra_quant.five_extra_cable_glands ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            five_extra_cable_glands: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="5 Extra Cable Glands" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_rj45_female_to_female_connectors"
                                                checked={data.extra_quant.ten_rj45_female_to_female_connectors ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_rj45_female_to_female_connectors: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 RJ45 Female to Female Connectors" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='extra_quant'
                                                name="ten_rj45_clips"
                                                checked={data.extra_quant.ten_rj45_clips ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        extra_quant: {
                                                            ...prev.extra_quant,
                                                            ten_rj45_clips: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="10 RJ45 Clips" />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="two_extra_sensor_housing" name='two_extra_sensor_housing' label="2 Extra Sensor Housing" variant="outlined" placeholder='ID'
                                        multiline
                                        value={data.extra_quant.two_extra_sensor_housing}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    two_extra_sensor_housing: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="two_extra_tcm" name='two_extra_tcm' label="2 Extra TCM" variant="outlined" placeholder='ID'
                                        multiline
                                        value={data.extra_quant.two_extra_tcm}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    two_extra_tcm: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="solar_panel_and_bracket" name='solar_panel_and_bracket' label="Solar Panel and Bracket" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.extra_quant.solar_panel_and_bracket}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    solar_panel_and_bracket: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="solar_batteries" name='solar_batteries' label="Solar Batteries" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.extra_quant.solar_batteries}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    solar_batteries: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="bolts" name='bolts' label="Bolts" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.extra_quant.bolts}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    bolts: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="battery_packs" name='battery_packs' label="Battery Packs" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.extra_quant.battery_packs}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    battery_packs: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="special_requirements" name='special_requirements' label="Special Requirements" variant="outlined" placeholder='Notes'
                                        value={data.extra_quant.special_requirements}
                                        disabled={disableField}
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                extra_quant: {
                                                    ...prev.extra_quant,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Borehole
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: '85%' }}>
                                <InputLabel id="borehole_checklist">Borehole Drilling</InputLabel>
                                <Select
                                    required
                                    id="borehole_drilling"
                                    name="borehole_drilling"
                                    labelId="borehole_checklist"
                                    value={data.borehole_drilling}
                                    label="Borehole Drilling"
                                    disabled={disableField}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            borehole_drilling: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.borehole_drilling &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="bentonite_prepacks" name='bentonite_prepacks' label="Bentonite Prepacks" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.borehole.bentonite_prepacks}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    bentonite_prepacks: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="outer_casing_solid" name='outer_casing_solid' label="Outter Casing Solid" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.borehole.outer_casing_solid}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    outer_casing_solid: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="outer_casing_slotted" name='outer_casing_slotted' label="Outer Casing Slotted" variant="outlined" placeholder='Quantity/Spacing'
                                        multiline
                                        value={data.borehole.outer_casing_slotted}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    outer_casing_slotted: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl sx={{ width: "85%" }}>
                                        <InputLabel id="borehole_checklist_casing_cap">Outer Casing Cap</InputLabel>
                                        <Select
                                            required
                                            id="outer_casing_cap"
                                            name="outer_casing_cap"
                                            labelId="borehole_checklist_casing_cap"
                                            value={data.borehole.outer_casing_cap}
                                            disabled={disableField}
                                            label="Outer Casing Cap"
                                            onChange={(e) => {
                                                let value = e.target.value
                                                setData(prev => ({
                                                    ...prev,
                                                    borehole: {
                                                        ...prev.borehole,
                                                        outer_casing_cap: value
                                                    }
                                                }))
                                            }}
                                        >
                                            <MenuItem value={'male'} key={1}>{'Male'}</MenuItem>
                                            <MenuItem value={'female'} key={2}>{'Female'}</MenuItem>
                                            <MenuItem value={'both'} key={3}>{'Both'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="flushmounts" name='flushmounts' label="Flushmounts" variant="outlined" placeholder='Quantity/Size'
                                        multiline
                                        value={data.borehole.flushmounts}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    flushmounts: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="stick_up" name='stick_up' label="Stick Up" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.borehole.stick_up}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    stick_up: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="stick_up_caps" name='stick_up_caps' label="Stick Up" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.borehole.stick_up_caps}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    stick_up_caps: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="special_requirements" name='special_requirements' label="Special Requirements" variant="outlined" placeholder='Notes'
                                        value={data.borehole.special_requirements}
                                        disabled={disableField}
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                borehole: {
                                                    ...prev.borehole,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Distributor
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: "85%" }}>
                                <InputLabel id="installing_distributor_label">Installing Distributor</InputLabel>
                                <Select
                                    required
                                    id="installing_distributor"
                                    name="installing_distributor"
                                    labelId="installing_distributor_label"
                                    value={data.installing_distributor}
                                    label="Working on Distributor"
                                    disabled={disableField}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            installing_distributor: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.installing_distributor &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="distributor_id" name='distributor_id' label="Distributor ID(s)" variant="outlined" placeholder='IDs'
                                        value={data.distributor.distributor_id}
                                        disabled={disableField}
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    distributor_id: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="pressure_gauge"
                                                checked={data.distributor.pressure_gauge ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            pressure_gauge: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Pressure Gauge" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="flowrate_meter"
                                                checked={data.distributor.flowrate_meter ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            flowrate_meter: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label='Flowrate Meter' />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='tubing' label="Tubing" variant="outlined" placeholder='Size/Length'
                                        multiline
                                        value={data.distributor.tubing}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    tubing: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="garden_hose"
                                                checked={data.distributor.garden_hose ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            garden_hose: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Garden Hose" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="extension_cord_or_power_cable"
                                                checked={data.distributor.extension_cord_or_power_cable ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            extension_cord_or_power_cable: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Extension Cord/Power Cable" />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='cam_locks_female' label="Cam locks Female" variant="outlined" placeholder='Quantity/Size'
                                        multiline
                                        value={data.distributor.cam_locks_female}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    cam_locks_female: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='cam_locks_male' label="Cam locks Male" variant="outlined" placeholder='Quantity/Size'
                                        multiline
                                        value={data.distributor.cam_locks_male}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    cam_locks_male: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='manifold' label="Manifold" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.distributor.manifold}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    manifold: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='emitters' label="Emitters" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.distributor.emitters}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    emitters: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='chemicals' label="Chemicals (L)" variant="outlined" placeholder='Quantity'
                                        multiline
                                        value={data.distributor.chemicals}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    chemicals: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="water_tank_100_L"
                                                checked={data.distributor.water_tank_100_L ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            water_tank_100_L: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="100 L Water Tank" />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='respirator' label="Respirator" variant="outlined" placeholder='Who Trained'
                                        multiline
                                        value={data.distributor.respirator}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    respirator: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="two_chemical_suits"
                                                checked={data.distributor.two_chemical_suits ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            two_chemical_suits: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="2 Chemical Suits" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="chem_pump"
                                                checked={data.distributor.chem_pump ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            chem_pump: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Chem Pump" />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor'
                                                name="water_pump"
                                                checked={data.distributor.water_pump ? true : false}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor: {
                                                            ...prev.distributor,
                                                            water_pump: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Water Pump" />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='tube_connectors' label="Tube Connectors Requirements" variant="outlined" placeholder='Quantity/Type'
                                        multiline
                                        value={data.distributor.tube_connectors}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    tube_connectors: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor" name='special_requirements' label="Special Requirements" variant="outlined" placeholder='Notes'
                                        value={data.distributor.special_requirements}
                                        disabled={disableField}
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor: {
                                                    ...prev.distributor,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Distributor Consumables
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: "85%" }}>
                                <InputLabel id="distributor_consumables_taking_label">Taking Consumables</InputLabel>
                                <Select
                                    required
                                    id="distributor_consumables_taking"
                                    name="distributor_consumables_taking"
                                    labelId="distributor_consumables_taking_label"
                                    value={data.distributor_consumables_taking}
                                    label="Working on Distributor"
                                    disabled={disableField}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            distributor_consumables_taking: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.distributor_consumables_taking &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.check_all ? true : false}
                                                onChange={(e) => {
                                                    let { checked } = e.target
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            check_all: e.target.checked,
                                                            extra_1_4_tubing: checked,
                                                            extra_1_2_tubing: checked,
                                                            extra_3_4_tubing: checked,
                                                            cam_locks_male_x5: checked,
                                                            cam_locks_Female_x5: checked,
                                                            emitters_x_20: checked,
                                                            distributor_bolts_x_50: checked,
                                                            extra_chemical_pump: checked,
                                                            extra_manifold_and_parts: checked,
                                                            extra_extension_cord: checked,
                                                            hose_clamps_1_4_x_20: checked,
                                                            hose_clamps_1_2_x_20: checked,
                                                            hose_clamps_3_4_x_20: checked,
                                                            hose_clamps_2_5_x_10: checked,
                                                            tube_coupler_1_4_x_10: checked,
                                                            tube_coupler_1_2_x_10: checked,
                                                            tube_coupler_3_4_x_10: checked,
                                                            fht_3_4_x_1_2: checked,
                                                            T_1_4_x_10: checked,
                                                            T_1_2_x_10: checked,
                                                            T_3_3_x_10: checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Check All`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_1_4_tubing ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_1_4_tubing: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`5m Extra 1/4" Tubing`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_1_2_tubing ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_1_2_tubing: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`5m Extra 1/2" Tubing`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_3_4_tubing ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_3_4_tubing: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`5m Extra 3/4" Tubing`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.cam_locks_male_x5 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            cam_locks_male_x5: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Cam Locks Male x5`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.cam_locks_Female_x5 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            cam_locks_Female_x5: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Cam Locks Female x5`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.emitters_x_20 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            emitters_x_20: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Emitters x20`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.distributor_bolts_x_50 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            distributor_bolts_x_50: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Distributor Bolts x50`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_chemical_pump ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_chemical_pump: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Extra Chemical Pump`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_manifold_and_parts ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_manifold_and_parts: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Extra Manifold and Parts`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.extra_extension_cord ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            extra_extension_cord: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`Extra Extension Cord`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.hose_clamps_1_4_x_20 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            hose_clamps_1_4_x_20: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/4" Hose Clamps x20`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.hose_clamps_1_2_x_20 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            hose_clamps_1_2_x_20: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/2" Hose Clamps x20`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.hose_clamps_3_4_x_20 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            hose_clamps_3_4_x_20: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`3/4" Hose Clamps x20`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.hose_clamps_2_5_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            hose_clamps_2_5_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`2-5" Hose Clamps x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.tube_coupler_1_4_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            tube_coupler_1_4_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/4" Tube Coupler x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.tube_coupler_1_2_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            tube_coupler_1_2_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/2" Tube Coupler x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.tube_coupler_3_4_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            tube_coupler_3_4_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`3/4" Tube Coupler x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.fht_3_4_x_1_2 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            fht_3_4_x_1_2: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`3/4" FHT x 1/2" tube barb (garden hose adaptor) x5`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.T_1_4_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            T_1_4_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/4" T x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.T_1_2_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            T_1_2_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`1/2" T x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id='distributor_consumables'
                                                disabled={disableField}
                                                checked={data.distributor_consumables.T_3_3_x_10 ? true : false}
                                                onChange={(e) => {
                                                    setData(prev => ({
                                                        ...prev,
                                                        distributor_consumables: {
                                                            ...prev.distributor_consumables,
                                                            T_3_3_x_10: e.target.checked
                                                        }
                                                    }))
                                                }}
                                            />
                                        }
                                        label={`3/3" T x10`} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="distributor_consumables" name='special_requirements' label="Special Requirements" variant="outlined"
                                        value={data.distributor_consumables.special_requirements}
                                        disabled={disableField}
                                        placeholder='Notes'
                                        multiline
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                distributor_consumables: {
                                                    ...prev.distributor_consumables,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{ justifyContent: 'center' }}>
                                <Checkbox
                                    id='safety'
                                    name="check_all"
                                    disabled={disableField}
                                    checked={data.safety.check_all ? true : false}
                                    onChange={(e) => {
                                        let { checked } = e.target
                                        setData(prev => ({
                                            ...prev,
                                            safety: {
                                                ...prev.safety,
                                                check_all: checked,
                                                safety_glasses: checked,
                                                hivis_vests: checked,
                                                coveralls: checked,
                                                hard_hats: checked,
                                                nitril_gloves: checked,
                                                working_gloves: checked,
                                                first_aid_kit: checked,
                                                fire_extinguisher: checked,
                                                bug_spary: checked,
                                                sun_screen: checked,
                                                steel_toed_boots: checked,
                                                marking_tape: checked,
                                                safety_cones: checked,
                                                driving_log: checked,
                                                field_manual: checked,
                                                site_impl_plan: checked,
                                                spill_kit: checked,
                                                eye_wash: checked
                                            }
                                        }))
                                    }}
                                />
                                <Typography variant="h6" sx={{ alignSelf: 'center' }}>
                                    Safety Checklist
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="safety_glasses"
                                        disabled={disableField}
                                        checked={data.safety.safety_glasses ? true : false}
                                        onChange={handleChange}
                                    />
                                }
                                label="Safety Glasses" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="hivis_vests"
                                        checked={data.safety.hivis_vests ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Hi-vis vests" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="coveralls"
                                        checked={data.safety.coveralls ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Coveralls" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="hard_hats"
                                        checked={data.safety.hard_hats ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Hard Hats" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="nitril_gloves"
                                        checked={data.safety.nitril_gloves ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Nitril Gloves" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="working_gloves"
                                        checked={data.safety.working_gloves ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Working Gloves" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="first_aid_kit"
                                        checked={data.safety.first_aid_kit ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="First aid kit" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="fire_extinguisher"
                                        checked={data.safety.fire_extinguisher ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Fire Extinguisher" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="bug_spary"
                                        checked={data.safety.bug_spary ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Bug Spray" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="sun_screen"
                                        checked={data.safety.sun_screen ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Sun Screen" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="steel_toed_boots"
                                        checked={data.safety.steel_toed_boots ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Steel Toed Boots" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="marking_tape"
                                        checked={data.safety.marking_tape ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Marking Tape" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="safety_cones"
                                        checked={data.safety.safety_cones ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Safety Cones" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="driving_log"
                                        checked={data.safety.driving_log ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Driving Log" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="field_manual"
                                        checked={data.safety.field_manual ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Field Manual" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="site_impl_plan"
                                        checked={data.safety.site_impl_plan ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Site Implementation Plan (Hard Copy)" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="spill_kit"
                                        checked={data.safety.spill_kit ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Spill Kit" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='safety'
                                        name="eye_wash"
                                        checked={data.safety.eye_wash ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Eye Wash" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField id="safety" name='special_requirements' label="Special Requirements" variant="outlined"
                                value={data.safety.special_requirements}
                                disabled={disableField}
                                placeholder='Notes'
                                multiline
                                onChange={(e) => {
                                    setData(prev => ({
                                        ...prev,
                                        safety: {
                                            ...prev.safety,
                                            special_requirements: e.target.value
                                        }
                                    }))
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Stack direction='row' sx={{ justifyContent: 'center' }}>
                                <Checkbox
                                    id='general'
                                    name="check_all"
                                    disabled={disableField}
                                    checked={data.general.check_all ? true : false}
                                    onChange={(e) => {
                                        let { checked } = e.target
                                        setData(prev => ({
                                            ...prev,
                                            general: {
                                                ...prev.general,
                                                check_all: checked,
                                                ipad_think_pad: checked,
                                                ipad_think_pad_charger: checked,
                                                shovel_trowels: checked,
                                                ratchet_straps: checked,
                                                hand_saw: checked,
                                                zip_ties: checked,
                                                scissors: checked,
                                                utility_knife: checked,
                                                vice_grips: checked,
                                                screw_driver_set: checked,
                                                socket_set: checked,
                                                wrench_set: checked,
                                                pin_finder: checked,
                                                water_level_measurer: checked,
                                                extra_bailers: checked,
                                                auto_goop: checked,
                                                duct_tape: checked,
                                                drills: checked,
                                                drill_charger: checked,
                                                drill_batteries: checked,
                                                teflon_tape: checked,
                                                tape_measure: checked,
                                                waterproof_tape: checked,
                                                wire_strippers: checked,
                                                rj45_crimper: checked,
                                                cat5e_tester: checked,
                                                power_cable: checked,
                                                synthetic_grease: checked,
                                                tera_term_cable: checked,
                                                heat_gun: checked,
                                                portable_power_pack: checked,
                                                dielectric_grease: checked,
                                                cigarette_ligher_inverter: checked,
                                                plumbers_strapping: checked
                                            }
                                        }))
                                    }}
                                />
                                <Typography variant="h6" sx={{ alignSelf: 'center' }}>
                                    General Checklist
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="ipad_think_pad"
                                        checked={data.general.ipad_think_pad ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Ipad or Thinkpad" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="ipad_think_pad_charger"
                                        checked={data.general.ipad_think_pad_charger ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Ipad or Thinkpad Charger" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="shovel_trowels"
                                        checked={data.general.shovel_trowels ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Shovel and trowels " />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="ratchet_straps"
                                        checked={data.general.ratchet_straps ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Ratchet straps" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="hand_saw"
                                        checked={data.general.hand_saw ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Hand Saw" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="zip_ties"
                                        checked={data.general.zip_ties ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Zip Ties" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="scissors"
                                        checked={data.general.scissors ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Scissors" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="utility_knife"
                                        checked={data.general.utility_knife ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Utility Knife" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="vice_grips"
                                        checked={data.general.vice_grips ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Vice Grips" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="screw_driver_set"
                                        checked={data.general.screw_driver_set ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Screw Driver Set" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="socket_set"
                                        checked={data.general.socket_set ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Socket Set" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="wrench_set"
                                        checked={data.general.wrench_set ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Wrench Set" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="pin_finder"
                                        checked={data.general.pin_finder ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="PIN Finder" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="water_level_measurer"
                                        checked={data.general.water_level_measurer ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Water Level Measurer" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="extra_bailers"
                                        checked={data.general.extra_bailers ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Bailers x5 Extra" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="auto_goop"
                                        checked={data.general.auto_goop ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Auto Goop" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="duct_tape"
                                        checked={data.general.duct_tape ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Duct Tape" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="drills"
                                        checked={data.general.drills ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Drills" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="drill_charger"
                                        checked={data.general.drill_charger ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Drill Charger" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="drill_batteries"
                                        checked={data.general.drill_batteries ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Drill Batteries" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="teflon_tape"
                                        checked={data.general.teflon_tape ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Teflon Tape" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="tape_measure"
                                        checked={data.general.tape_measure ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Tape Measure" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="waterproof_tape"
                                        checked={data.general.waterproof_tape ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Waterproof Tape" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="wire_strippers"
                                        checked={data.general.wire_strippers ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Wire Strippers" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="rj45_crimper"
                                        checked={data.general.rj45_crimper ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="RJ45 Crimper" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="cat5e_tester"
                                        checked={data.general.cat5e_tester ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Cat5e Tester" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="power_cable"
                                        checked={data.general.power_cable ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Power Cable" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="synthetic_grease"
                                        checked={data.general.synthetic_grease ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Synthetic Grease" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="tera_term_cable"
                                        checked={data.general.tera_term_cable ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Tera Term Cable" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="heat_gun"
                                        checked={data.general.heat_gun ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Heat Gun" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="portable_power_pack"
                                        checked={data.general.portable_power_pack ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Portable Power Pack" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="dielectric_grease"
                                        checked={data.general.dielectric_grease ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Dielectric Grease" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="cigarette_lighter_inverter"
                                        checked={data.general.cigarette_ligher_inverter ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Cigarette Lighter Inverter" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='general'
                                        name="plumbers_strapping"
                                        checked={data.general.plumbers_strapping ? true : false}
                                        disabled={disableField}
                                        onChange={handleChange}
                                    />
                                }
                                label="Plumbers Strapping" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField id="general" name='special_requirements' label="Special Requirements" variant="outlined"
                                value={data.general.special_requirements}
                                disabled={disableField}
                                placeholder='Notes'
                                multiline
                                onChange={(e) => {
                                    setData(prev => ({
                                        ...prev,
                                        general: {
                                            ...prev.general,
                                            special_requirements: e.target.value
                                        }
                                    }))
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Tracer
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ width: '85%' }}>
                                <InputLabel id="working_tracer">Working with Tracer</InputLabel>
                                <Select
                                    required
                                    id="working_tracer"
                                    name="working_tracer"
                                    labelId="working_tracer"
                                    value={data.working_tracer}
                                    disabled={disableField}
                                    label="Working with Tracer"
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setData(prev => ({
                                            ...prev,
                                            working_tracer: value
                                        }))
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'Yes'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'No'}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            data.working_tracer &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField id="tracer" name='funnel_measuring_cup' label="Funnel or Measuring cup " variant="outlined"
                                        multiline
                                        value={data.tracer.funnel_measuring_cup}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                tracer: {
                                                    ...prev.tracer,
                                                    funnel_measuring_cup: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="tracer" name='tracer' label="Tracer" variant="outlined"
                                        multiline
                                        value={data.tracer.tracer}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                tracer: {
                                                    ...prev.tracer,
                                                    tracer: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="tracer" name='carbon_tracer_bags' label="Carbon Tracer bags" variant="outlined"
                                        multiline
                                        value={data.tracer.carbon_tracer_bags}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                tracer: {
                                                    ...prev.tracer,
                                                    carbon_tracer_bags: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField id="special_requirements" name='special_requirements' label="Special Requirements" variant="outlined"
                                        multiline
                                        placeholder='Notes'
                                        value={data.tracer.special_requirements}
                                        disabled={disableField}
                                        onChange={(e) => {
                                            setData(prev => ({
                                                ...prev,
                                                tracer: {
                                                    ...prev.tracer,
                                                    special_requirements: e.target.value
                                                }
                                            }))
                                        }}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12}>
                            <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                <Typography variant="h6">
                                    Entered by
                                </Typography>
                                <Typography variant="h6" color={'red'}>
                                    {!disableField ? data.user_name : props.data.user_name}
                                </Typography>
                            </Stack>
                        </Grid>
                        {(disableField && !props.data.sign_off_name) &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='general'
                                            name="sign_off"
                                            checked={data.sign_off ? true : false}
                                            onChange={(e) => {
                                                let value = e.target.checked
                                                setData(prev => ({
                                                    ...prev,
                                                    sign_off: value,
                                                    sign_off_name: ls.getLocalStorageItemZoho().user_name,
                                                    sign_off_id: ls.getLocalStorageItemZoho().user_id
                                                }))
                                            }}
                                        />
                                    }
                                    label="Sign off" />
                            </Grid>
                        }
                        {(disableField && props.data.sign_off_name) &&
                            <Grid item xs={12}>
                                <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                    <Typography variant="h6">
                                        Sign off by
                                    </Typography>
                                    <Typography variant="h6" color={'red'}>
                                        {props.data.sign_off_name}
                                    </Typography>
                                </Stack>
                            </Grid>
                        }
                    </Grid>
                    {
                        !disableField &&
                        <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                            <Grid item xs={12} sx={{justifyContent: 'center'}}>
                                <Button variant="contained" onClick={handleSubmit} size="large" startIcon={<CloudUploadIcon />}>Submit</Button>
                            </Grid>
                        </Grid>
                    }
                    {
                        disableField &&
                        <Grid container rowSpacing={5} columnSpacing={2} mt={2} alignItems="center" justify="center">
                            <Grid item xs={6}>
                                <Button variant="contained" disabled={props.data === data} onClick={handleUpdate} size="large" startIcon={<CloudUploadIcon />}>Update</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="warning" onClick={props.handleDetailDialogClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}